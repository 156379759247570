import { Component } from 'react'
import { Popper } from 'react-popper'
import { Portal } from 'react-portal'
import PropTypes from 'prop-types'
import cc from 'classcat'

import Resizable from './Resizable'

export default class Popover extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    className: PropTypes.string,
    isResizable: PropTypes.bool,
    withoutPortal: PropTypes.bool,
    variant: PropTypes.oneOf(['light', 'dark']),
    onEnterKeyDown: PropTypes.func,
    onEscapeKeyDown: PropTypes.func,
    onOutsideMouseDown: PropTypes.func,
    onOutsideClick: PropTypes.func,

    // Props used with Popper.js:
    placement: PropTypes.string,
    flip: PropTypes.bool,
    offsetSkidding: PropTypes.number,
    offsetDistance: PropTypes.number,
    popperModifiers: PropTypes.array,
    referenceElement: PropTypes.any,
  }

  componentDidMount() {
    window.requestAnimationFrame(() => {
      if (this.ref && !this.ref.contains(document.activeElement)) {
        this.ref.focus()
      }
    })
    document.addEventListener('mousedown', this.handleDocumentMouseDown)
    document.addEventListener('click', this.handleDocumentClick, { capture: true })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown)
    document.removeEventListener('click', this.handleDocumentClick, { capture: true })
  }

  handleDocumentMouseDown = (event) => {
    if (this.ref && !this.ref.contains(event.target)) this.props.onOutsideMouseDown?.(event)
  }

  handleDocumentClick = (event) => {
    if (this.ref && !this.ref.contains(event.target)) this.props.onOutsideClick?.(event)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape') this.props.onEscapeKeyDown?.(event)
    if (event.key === 'Enter') this.props.onEnterKeyDown?.(event)
  }

  setRef = (node) => {
    this.ref = node
  }

  render() {
    const {
      className,
      children,
      placement = 'auto',
      flip = true,
      offsetSkidding,
      offsetDistance,
      isResizable = true,
      withoutPortal = false,
      variant = 'light',
      referenceElement,
    } = this.props

    const popperModifiers = [
      { name: 'preventOverflow', options: { boundary: 'viewport' } },
      { name: 'hide', enabled: false },
      { name: 'flip', enabled: flip, options: { flipVariations: false } },
      { name: 'offset', options: { offset: [offsetSkidding, offsetDistance] } },
      ...(this.props.popperModifiers || []),
    ]

    const popper = (
      <Popper
        innerRef={this.setRef}
        placement={placement}
        modifiers={popperModifiers}
        referenceElement={referenceElement}
      >
        {({ ref, style, placement, arrowProps, update }) => {
          const content = (
            <div
              ref={ref}
              style={style}
              data-placement={placement}
              className={cc([className, 'ep-popover', `ep-popover-${variant}`])}
              tabIndex="-1"
              onKeyDown={this.handleKeyDown}
            >
              {typeof children === 'function' ? children({ update }) : children}
              <span ref={arrowProps.ref} style={arrowProps.style} className="ep-popover-arrow" />
            </div>
          )

          return isResizable ? <Resizable onResize={update}>{content}</Resizable> : content
        }}
      </Popper>
    )

    return withoutPortal ? popper : <Portal>{popper}</Portal>
  }
}
