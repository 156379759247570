import { useEffect, useState } from 'react'

/**
 * Indicates whether a loading indicator should be shown for the given promise.
 *
 * A loading indicator should be shown if the given promise has not been
 * resolved or rejected almost instantly, within 500 milliseconds.
 *
 * @returns An array containing a boolean indicating whether the loading
 * indicator should be shown, and a function to set the promise to be settled
 * before the indicator is hidden.
 */
export function useLoadingIndicator() {
  const [shouldShowLoadingIndicator, setShouldShowLoadingIndicator] = useState(false)
  const [promise, setPromise] = useState<Promise<void>>()

  useEffect(() => {
    if (promise) {
      const loadingIndicatorTimeout = setTimeout(() => {
        setShouldShowLoadingIndicator(true)
      }, 500)

      promise.finally(() => {
        clearTimeout(loadingIndicatorTimeout)
        setShouldShowLoadingIndicator(false)
      })

      return () => clearTimeout(loadingIndicatorTimeout)
    }
  }, [promise])

  return [shouldShowLoadingIndicator, setPromise] as const
}
