import { useSelector } from 'react-redux'
import Immutable from 'immutable'

import { DocumentMeta } from '../components/DocumentMeta'
import { useTranslate } from '../utils/translate'
import Theme from '../components/Theme'

function UnknownRoute() {
  const notFoundError = Immutable.Map({ statusCode: 404 })
  const t = useTranslate('shop', 'views.storefrontView.errorPageSection')
  const shopTitle = useSelector<State, string>((state) => state.getIn(['shop', 'title']))

  return (
    <>
      <DocumentMeta title={`${t('errorcode', { code: 404 })} - ${shopTitle}`} />
      <Theme withLayout error={notFoundError} currentView="notFound" />
    </>
  )
}

export default UnknownRoute
