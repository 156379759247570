import { Provider } from 'react-redux'
import type { ReactNode } from 'react'
import type { Store } from 'redux'
import cc from 'classcat'

type RootProps = Readonly<{
  store: Store
  children: ReactNode
  headElements?: ReactNode
}>

export function Root({ store, children, headElements = '' }: RootProps) {
  const state = store.getState()
  const locale = state.getIn(['shop', 'locale'])
  const isNowPreview = state.hasIn(['location', 'query', 'isNowPreview'])

  // Suppressing hydration warnings because the no-js class is removed on the
  // client in "start.js".
  return (
    <html
      lang={locale.substring(0, 2)}
      className={cc(['no-js', isNowPreview && 'ep-mbo-preview-mode'])}
      suppressHydrationWarning={true}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {headElements}
      </head>
      <body className={cc([isNowPreview && 'ep-mbo-preview-mode'])}>
        <Provider store={store}>{children}</Provider>
      </body>
    </html>
  )
}
