import { mapValues, set } from 'lodash-es'

import * as actions from '../actions'
import { getPlain } from '../utils'

export default function search(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.SELECT_FACETED_SEARCH_FACET_VALUE: {
      return state.updateIn(['searchData', 'facets'], (rawFacets) => {
        const facets = getPlain<Core.Facets>(rawFacets)
        const { facetId, valueId, selected } = action.payload
        if (facets[facetId].values) {
          return set({ ...facets }, [facetId, 'values', valueId, 'selected'], selected)
        } else return state
      })
    }
    case actions.SET_FACETED_SEARCH_FACET_RANGE: {
      const { range, facetId } = action.payload
      return state.updateIn(['searchData', 'facets'], (rawFacets) => {
        const facets = getPlain<Core.Facets>(rawFacets)
        const oldRange: Core.FacetRange = facets[facetId].values?.range as Core.FacetRange
        return {
          ...facets,
          [facetId]: {
            ...facets[facetId],
            values: {
              ...facets[facetId].values,
              selection: range,
              selected: range.min !== oldRange.min || range.max !== oldRange.max,
            },
          },
        }
      })
    }
    case actions.RESET_ALL_FILTERS: {
      const facets = getPlain<Core.Facets>(state.getIn(['searchData', 'facets']))
      const newFacets = mapValues(facets, (facet) => {
        if (facet.type === 'selection') {
          return set(
            facet,
            'values',
            mapValues(facet.values, (value) => set(value, 'selected', false)),
          )
        } else if (facet.type === 'range') {
          const values = facet.values
          if (values) {
            return set(facet, 'values', {
              ...values,
              selected: false,
              selection: values.range,
            })
          }
        }
      })
      return state.setIn(['searchData', 'facets'], newFacets)
    }
    default:
      return state
  }
}
