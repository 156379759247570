import type { ReactElement } from 'react'
import type { RouteComponentProps } from 'react-router'
import { set } from 'lodash-es'
import { useSelector } from 'react-redux'

import Category from './Category'
import Page from './Page'

const shouldUsePageComponent = (state: State) => Boolean(state.getIn(['shop', 'beyond']))

function setRouterProps(props: Props, usePageComponent: boolean) {
  if (usePageComponent) return set(props, 'params.pageSlug', 'home')
  return {
    ...props,
    isHomepage: !props.params.splat,
  }
}

type Props = RouteComponentProps<any, any>
export default function Home(props: Props): ReactElement {
  const usePageComponent = useSelector<State, boolean>(shouldUsePageComponent)
  const Component = usePageComponent ? Page : Category

  return <Component {...setRouterProps(props, usePageComponent)} />
}

Home.storeUpdate = (props: Props, state: State) => {
  const usePageComponent = shouldUsePageComponent(state)
  const Component = usePageComponent ? Page : Category

  return Component.storeUpdate(setRouterProps(props, usePageComponent), state)
}
