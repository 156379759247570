import type { RefObject } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function useUpdateSettingsLayerPosition(
  updateSettingsLayerPosition: RefObject<VoidFunction | undefined>,
  activeTab?: string,
): void {
  const pathname = useSelector<State, string>((state) => state.getIn(['location', 'pathname']))

  useEffect(() => {
    // Update the settings layer's position because its width and/or height could've been changed.
    if (updateSettingsLayerPosition.current) updateSettingsLayerPosition.current()
  }, [activeTab, updateSettingsLayerPosition])

  // Changing the name of a currently open content page changes the URL path.
  // If a bottom bar settings layer is open during such a name change, the
  // popper positioning becomes skewed.
  // The following repositions the settings layer correctly again.
  useEffect(() => {
    if (updateSettingsLayerPosition.current) setTimeout(updateSettingsLayerPosition.current, 1)
  }, [pathname, updateSettingsLayerPosition])
}
