import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { addLegacyProductProperties, track } from '../../../../../utils/tracking'
import ProductListItem from '../../../ProductListItem'

type Props = {
  product: Frontend.Product
}

export default function DetailedProductView({ product }: Readonly<Props>): ReactElement {
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))

  const trackClick = (product: Frontend.Product, productIndex: number) => {
    track('product:click', { type: 'teaser', product: addLegacyProductProperties(product, isBeyond), productIndex })
  }

  return <ProductListItem withImageAspectRatio product={product} productIndex={0} trackProductClick={trackClick} />
}
