import type { ImgHTMLAttributes } from 'react'
import { useSelector } from 'react-redux'

import { useTranslate } from '../utils/translate'
import LazyImage from './LazyImage'

const widths = ['180', '360', '540', '720', '900', '1080'] as const

type Props = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'srcSet' | 'sizes' | 'alt'>

export default function PlaceholderImage(props: Readonly<Props>) {
  const hashedThemeAssets = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'hashedThemeAssets']))

  const t = useTranslate('shop')

  return (
    <LazyImage
      src={`/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
        'epages.base/assets/images',
        'placeholder_540.jpg',
      ])}`}
      srcSet={widths
        .map(
          (w) =>
            `/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
              'epages.base/assets/images',
              `placeholder_${w}.jpg`,
            ])} ${w}w`,
        )
        .join(',')}
      sizes="auto"
      alt={t('components.productComponent.placeholderImageAltText')}
      {...props}
    />
  )
}
