import { fromJS } from 'immutable'
import { useRef } from 'react'
import loadable from '@loadable/component'

import { useTranslate } from '../../../../../utils/translate'
import ErrorBoundary from '../../../../ErrorBoundary'

const RichTextEditor = loadable(() => import(/* webpackChunkName: "editor" */ './RichTextEditor'), { ssr: false })

export default function TextPlugin({
  editorView,
  data = fromJS({ content: '' }),
  onDataChange,
  onSave,
  onEdit,
}: Readonly<WorkspacePluginProps>) {
  const t = useTranslate('interface')
  const ref = useRef<HTMLElement>(null)

  const onChange = (html: string) => {
    const newData = data.set('content', html)
    onDataChange(newData)
    onSave(newData)
  }

  const onFocus = () => {
    onEdit()
  }

  const renderDefaultView = () => {
    return <div className="dali-plugin-text-content" dangerouslySetInnerHTML={{ __html: data.get('content') }} />
  }

  const renderErrorView = () => {
    return (
      <>
        <p className="dali-plugin-text-error-message">
          {t('components.richTextComponent.brokenRichTextEditorMessage')}
          <br />
          <code>{data.get('content')}</code>
        </p>
        {renderDefaultView()}
      </>
    )
  }

  const renderEditorView = () => {
    return (
      <ErrorBoundary fallback={renderErrorView()}>
        <RichTextEditor
          /* Force a remount whenever the content changes. Avoids syncing-work inside. */
          key={data.get('content') || ''}
          fallback={renderDefaultView()}
          html={data.get('content') || ''}
          onChange={onChange}
          onFocus={onFocus}
          setRef={(_node) => ref}
          isHeadline={data.get('isHeadline')}
        />
      </ErrorBoundary>
    )
  }

  return <div className="dali-plugin-text">{editorView ? renderEditorView() : renderDefaultView()}</div>
}

TextPlugin.actionBarButtons = {
  edit: true,
}
