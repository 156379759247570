import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { loadCart } from '../../../store/actions'
import { useTranslate } from '../../../utils/translate'
import Link from '../../templateComponents/Link'

type Props = { cartUrl: string }

export default function MiniCart({ cartUrl }: Readonly<Props>) {
  const t = useTranslate('shop', 'components.miniCartComponent')

  const isEcommerceDisabled = useSelector<State, boolean>((state) =>
    Boolean(
      state
        .getIn(['shop', 'attributes'])
        .find(
          (attribute: ImmutableMap) =>
            attribute.get('name') === 'ecommerce:disabled' && attribute.get('value') === 'true',
        ),
    ),
  )

  const cart = useSelector<State, ImmutableMap>((state) => state.get('cart'))
  const dispatch = useDispatch()

  useEffect(() => {
    if (!cart) dispatch(loadCart())
  }, [dispatch, cart])

  if (isEcommerceDisabled) return null

  return (
    <div className="header-minicart">
      <Link to={cartUrl} className="header-minicart-checkout">
        <span className="visually-hidden">
          {t('cartIcon.accessibilityLabel', {
            count: cart?.get('totalNumberOfItems', 0),
          })}{' '}
        </span>
        <span className="header-minicart-icon" aria-hidden="true" />
        <span className="header-minicart-badge" aria-hidden="true">
          {cart ? cart.get('totalNumberOfItems', 0) : ''}
        </span>
      </Link>
    </div>
  )
}
