import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { useTranslate } from '../utils/translate'

export default function PreviewBar(): ReactElement {
  const isShopClosed = useSelector<State, boolean>((state) => state.getIn(['shop', 'isClosed']))
  const themeTitle = useSelector<State, string>((state) => state.getIn(['location', 'query', 'themeTitle']))

  const t = useTranslate('shop')

  return (
    <div className="ep-preview-bar mobile-view-sm">
      {themeTitle && (
        <span className="ep-preview-bar-theme-headline">
          {t('components.previewBarComponent.themeHeadline', { theme: themeTitle })}
        </span>
      )}
      {isShopClosed && (
        <span className="ep-preview-bar-closed-text">{t('components.previewBarComponent.shopClosedMessage')}</span>
      )}
      <span className="ep-preview-bar-info">{t('components.previewBarComponent.title')}</span>
    </div>
  )
}
