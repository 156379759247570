import type { PhotoProps } from 'react-photo-gallery'
import type { ReactElement, RefObject } from 'react'
import { useRef } from 'react'

import LazyImage from '../../../../LazyImage'
import Swiper from '../contentslider/Swiper'

type Props = {
  photos: PhotoProps<CustomPhotoProps>[]
  initialIndex?: number
}

type CustomPhotoProps = {
  originalWidth: number
  originalHeight: number
}

type SlideProps = {
  slide: PhotoProps<CustomPhotoProps>
}

type ArrowProps = {
  nextButtonRef: RefObject<HTMLDivElement | null>
  prevButtonRef: RefObject<HTMLDivElement | null>
}

type ContainerProps = {
  slides: PhotoProps<CustomPhotoProps>[]
  swiperContainerRef: RefObject<HTMLDivElement | null>
}

function Slide({ slide }: Readonly<SlideProps>) {
  return (
    <div className="swiper-slide">
      <LazyImage
        src={slide.src}
        width={slide.originalWidth}
        height={slide.originalHeight}
        alt={slide.alt}
        style={{ maxWidth: slide.originalWidth }}
        nofallback
      />
    </div>
  )
}

function ImageGalleryArrows({ nextButtonRef, prevButtonRef }: Readonly<ArrowProps>): ReactElement {
  return (
    <div className="swiper-arrows">
      <div ref={nextButtonRef} className="swiper-arrow-right" />
      <div ref={prevButtonRef} className="swiper-arrow-left" />
    </div>
  )
}

function SwiperMainSlideContainer({ slides, swiperContainerRef }: Readonly<ContainerProps>): ReactElement {
  return (
    <div className="swiper-container swiper-main-image" ref={swiperContainerRef}>
      <div className="swiper-wrapper">
        {slides.map((slide, index) => (
          <Slide key={index} {...{ slide, swiperContainerRef }} />
        ))}
      </div>
    </div>
  )
}

export default function Carousel({ photos, initialIndex }: Readonly<Props>): ReactElement {
  const swiperContainerRef = useRef<HTMLDivElement>(null)
  const nextButtonRef = useRef<HTMLDivElement>(null)
  const prevButtonRef = useRef<HTMLDivElement>(null)

  return (
    <Swiper
      slides={photos}
      initialSlide={initialIndex}
      loop={false}
      grabCursor={true}
      keyboard={{ enabled: true }}
      refSet={{ swiperContainerRef, nextButtonRef, prevButtonRef }}
      renderSwiper={({ slides, slideIdx }) => (
        <>
          <SwiperMainSlideContainer {...{ slides, swiperContainerRef }} />
          <ImageGalleryArrows {...{ nextButtonRef, prevButtonRef }} />
          <div className="swiper-counter">
            {slideIdx + 1} / {slides.length}
          </div>
        </>
      )}
    />
  )
}
