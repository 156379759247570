import type { JSX } from 'react'
import type { List } from 'immutable'
import { camelCase } from 'lodash-es'
import { useSelector } from 'react-redux'

import type { CheckoutProps } from './Checkout'
import type { PersonalDataField } from './personalDataByCountry'
import personalDataFieldsByCountry from './personalDataByCountry'

const stepsTranslationScope = 'views.storefrontView.checkoutPageSection.steps'

/**
 * Returns a function that gives the translation key of a step.
 *
 * Example usage:
 * ```
 * const getStepTranslationKey = useStepTranslationKey()
 * const shippingKey = getStepTranslationKey('shipping')
 * ```
 */
export function useStepTranslationKey(): (stepId: CheckoutProps['stepId']) => string {
  const cart = useSelector<State, ImmutableMap>((state) => state.get('cart'))
  const shippingCountries = useSelector<State, ImmutableList>((state) => state.getIn(['checkout', 'countries']))

  function getStepTranslationKey(stepId: CheckoutProps['stepId']): string {
    if (stepId !== 'shipping') return `${stepsTranslationScope}.${camelCase(stepId)}.label`

    const showPickupLabel = cart.hasIn(['_links', 'pickup-option-available'])
    const showShippingLabel = shippingCountries.size > 0
    const showShippingAndPickupLabel = showShippingLabel && showPickupLabel

    if (showShippingAndPickupLabel) return `${stepsTranslationScope}.shipping.shippingAndPickup.label`
    if (showShippingLabel) return `${stepsTranslationScope}.shipping.label`
    if (showPickupLabel) return `${stepsTranslationScope}.shipping.pickup.label`

    return ''
  }

  return getStepTranslationKey
}

export function lineListToHTML(list: string[] | List<string>) {
  const line = list.map((line: string, idx: number) => (
    <span key={idx}>
      {line}
      <br />
    </span>
  ))

  // TODO: The explicit typing as `JSX.Element[]` is needed for using `immutableList.map()`
  // inside JSX code with Immutable v3 and React 18. Can be removed when updating to
  // Immutable v4 (https://epages.atlassian.net/browse/EPUI-1426).
  //
  // `list.map()` returns the type `Iterable<number, JSX.Element>` in Immutable v3. This
  // is incompatible when using inside JSX in React 18 because it "is not assignable to
  // type `ReactNode`".
  return line as JSX.Element[]
}

export function methodToString(method: ImmutableMap) {
  if (!method) return <span />
  return lineListToHTML([method.get('name'), method.get('description')].filter(Boolean))
}

export function addressToString(address: ImmutableMap | Record<string, any>) {
  if (!address?.get('displayAddressLines')) return <span />
  return lineListToHTML(address.get('displayAddressLines'))
}

export function addressTypeToString(address?: Pick<Core.Address, 'displayAddressLines'>) {
  if (!address?.displayAddressLines) return <span />
  return lineListToHTML(address.displayAddressLines)
}

export function areAddressesTheSame(address1?: Core.Address | null, address2?: Core.Address | null): boolean {
  if (!address1 && !address2) return true
  if (!address1 || !address2) return false

  const stringifiedAddress1 = JSON.stringify(address1.displayAddressLines)
  const stringifiedAddress2 = JSON.stringify(address2.displayAddressLines)

  return stringifiedAddress1 === stringifiedAddress2
}

export function isAddressValid(address?: Core.Address, mustEnterPhoneNumber?: boolean): boolean {
  if (!address) return false

  let mandatoryFields = personalDataFieldsByCountry(address.country).filter((field) => field.required === true)

  // If phone field is deactivated in the cockpit's checkout settings, we are removing it from the mandatory fields
  if (!mustEnterPhoneNumber) {
    mandatoryFields = mandatoryFields.filter((field) => field.name !== 'phone')
  }

  const isFilled = (field: PersonalDataField) => Boolean(address[field.name])

  return mandatoryFields.every(isFilled)
}
