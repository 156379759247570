import type { ReactElement } from 'react'
import cc from 'classcat'

import ProductListItem from '../../../ProductListItem'
import useLazyLoadMore from '../../../../../utils/hooks/useLazyLoadMore'

const classNames = {
  S: 'grid-col-s',
  M: 'grid-col-m',
  L: 'grid-col-l',
}

type Props = {
  products: Frontend.Product[]
  imageSize?: keyof typeof classNames
  pageSize: number
  loadMoreProducts: () => void
  trackProductClick: (product: Frontend.Product, productIndex: number) => void
}

export default function ProductPage({
  products,
  imageSize = 'M',
  pageSize,
  trackProductClick,
  loadMoreProducts,
}: Readonly<Props>): ReactElement {
  const inViewRef = useLazyLoadMore(pageSize, products.length, loadMoreProducts)

  return (
    <div className={cc(['product-list', classNames[imageSize]])}>
      {products.map((product, index) => (
        <ProductListItem
          product={product}
          productIndex={index}
          inViewRef={inViewRef(index)}
          key={product.productId}
          trackProductClick={trackProductClick}
        />
      ))}
    </div>
  )
}

export const productGridClassNames = classNames
