import type { JSX } from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'

import { useTranslate } from '../../../utils/translate'

const AccountMenu = loadable(() => import(/* webpackChunkName: "account" */ './Account/AccountMenu'))

type Props = {
  accountUrl?: string
  isPreview: boolean
}

export default function AccountIcon({ accountUrl, isPreview }: Readonly<Props>): JSX.Element | null {
  const isEcommerceDisabled = useSelector<State, boolean>((state) =>
    Boolean(
      state
        .getIn(['shop', 'attributes'])
        .find((attribute) => attribute.get('name') === 'ecommerce:disabled' && attribute.get('value') === 'true'),
    ),
  )

  const t = useTranslate('shop', 'components.accountMenuComponent.accountIcon')

  if (isEcommerceDisabled && !accountUrl) return null

  return (
    <div className="header-account">
      {accountUrl ? (
        <a
          {...(isPreview || !accountUrl ? {} : { href: accountUrl })}
          className="header-account-login"
          aria-label={t('accessibilityLabel')}
        >
          <span className="header-account-icon" />
        </a>
      ) : (
        <AccountMenu />
      )}
    </div>
  )
}
