import loadable from '@loadable/component'

import { imageUrl } from '../../ThemeView'
import { useTranslate } from '../../../utils/translate'
import LazyImage from '../../LazyImage'
import PlaceholderImage from '../../PlaceholderImage'

export const ZoomableImage = loadable(() => import(/* webpackChunkName: "Product" */ '../../ZoomableImage'))

type ProductImageProps = {
  alt: string
  image?: {
    isZoomEnabled: boolean
    url: string
    width: number
    height: number
  }
  productLabels: string[]
}

/** Renders the product image or corresponding placeholder, with optional label. */
export default function ProductImage({ image, alt, productLabels }: Readonly<ProductImageProps>) {
  const t = useTranslate('shop')

  const productLabelsMarkup =
    productLabels.length > 0 ? (
      <div className="product-image-label-wrapper">
        {productLabels.map((label) => (
          <span key={label} className={`product-image-label product-image-label-${label.toLowerCase()}`}>
            {t(`components.productComponent.imageLabels.${label}`)}
          </span>
        ))}
      </div>
    ) : null

  return (
    <div className="product-image">
      {image && image.isZoomEnabled ? (
        <>
          <ZoomableImage
            src={image.url}
            width={image.width}
            height={image.height}
            alt={alt}
            overlayContent={productLabelsMarkup}
          />
          <noscript>
            <img
              src={imageUrl(image.url, 600, 2560)}
              width={image.width}
              height={image.height}
              alt={alt}
              style={{ maxWidth: image.width }}
              itemProp="image"
            />
          </noscript>
        </>
      ) : (
        <div className="product-image-wrapper">
          {image ? (
            <>
              <LazyImage
                src={image.url}
                width={image.width}
                height={image.height}
                alt={alt}
                style={{ maxWidth: image.width }}
                nofallback
              />
              <noscript>
                <img
                  src={imageUrl(image.url, 600, 2560)}
                  width={image.width}
                  height={image.height}
                  alt={alt}
                  style={{ maxWidth: image.width }}
                  itemProp="image"
                />
              </noscript>
              {productLabelsMarkup ? (
                <div
                  className="product-image-overlay"
                  style={{
                    aspectRatio: `${image.width} / ${image.height}`,
                    maxWidth: image.width,
                    maxHeight: image.height,
                    width: image.width > image.height ? '100%' : undefined,
                    height: image.height >= image.width ? '100%' : undefined,
                  }}
                >
                  {productLabelsMarkup}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <PlaceholderImage itemProp="image" />
              {productLabelsMarkup && <div className="product-image-overlay">{productLabelsMarkup}</div>}
            </>
          )}
        </div>
      )}
    </div>
  )
}
