import type { ReactElement } from 'react'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import cc from 'classcat'
import loadable from '@loadable/component'

import { getPlain } from '../../../../../store/utils'
import { useTranslate } from '../../../../../utils/translate'
import DetailedProductView from './DetailedProductView'
import SimpleProductView from './SimpleProductView'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ProductSettings'))

const productViews = {
  simple: SimpleProductView,
  detailed: DetailedProductView,
}

export default function ProductPlugin({
  config,
  data,
  editorMode,
  editorView,
  onCancel,
  onDataChange,
  onEdit,
  onSave,
}: Readonly<WorkspacePluginProps>): ReactElement | null {
  const ref = useRef<HTMLDivElement>(null)
  const [isSettingActive, setIsSettingActive] = useState(false)
  const [error] = useState(false)

  const product = useSelector<State, Frontend.Product | undefined>((state) =>
    getPlain(state.getIn(['productData', 'productPlugin', data.get('productId')])),
  )

  const t = useTranslate('interface', 'components.productPluginComponent')

  const pluginActiveClasses = cc(['product-plugin', { 'dali-grid-element-highlighted': isSettingActive }])

  if (error || (!editorView && !product)) {
    return null
  }

  const ProductView = productViews[data.get('layout')]

  return (
    <div className={pluginActiveClasses} ref={ref}>
      {editorMode === 'edit' && (
        <SettingsLayer
          referenceElement={ref.current}
          placement="top"
          onActiveStateChange={(newActiveState) => setIsSettingActive(newActiveState)}
          onEscapeKeyDown={(event) => event.target.tagName !== 'INPUT' && onCancel()}
          className="dali-settingslayer-productshowcase"
        >
          {({ renderLayout }) => (
            <SettingsForm {...{ data, config, onDataChange, onCancel, onSave, renderLayout, t }} />
          )}
        </SettingsLayer>
      )}
      {product && <ProductView product={product} />}
      {!product && (
        <div className="dali-grid-element-placeholder">
          <span className="dali-plugin-productshowcase-placeholder" />
          <button className="dali-plugin-productshowcase-placeholder-button-add" type="button" onClick={() => onEdit()}>
            {t('selectProductButton.label')}
          </button>
        </div>
      )}
    </div>
  )
}

ProductPlugin.actionBarButtons = { save: false, edit: true }
