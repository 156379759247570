import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { addLegacyProductProperties, track } from '../../../../../utils/tracking'
import { useTranslate } from '../../../../../utils/translate'
import Link from '../../../Link'
import ProductImage from '../../../../themeComponents/epages.base/ProductImage'

type Props = {
  product: Frontend.Product
}

export default function SimpleProductView({ product }: Readonly<Props>): ReactElement {
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))

  const t = useTranslate('shop')

  return (
    <div className="dali-plugin-productshowcase-simpleview">
      <Link
        to={product.href}
        className="product-item-link"
        onClick={() =>
          track('product:click', {
            type: 'teaser',
            product: addLegacyProductProperties(product, isBeyond),
            productIndex: 0,
          })
        }
      >
        <ProductImage
          image={product.image && { ...product.image, isZoomEnabled: false }}
          alt={product.name}
          productLabels={product.labels}
        />
        <div className="dali-plugin-productshowcase-simpleview-cta">
          <button type="button" className="show-more-button">
            {t('components.productPluginComponent.simpleView.showProductButton.label')}
          </button>
        </div>
      </Link>
    </div>
  )
}
