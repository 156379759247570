import { useEffect, useRef, useState } from 'react'

import type { ImageMoveControls } from './ImageMoveControls'
import { getRelativeDelta } from './ImageEditor.helpers'

export function useImageOffset(
  canvas: HTMLCanvasElement | null,
  initialOffset: readonly [number, number],
  maxOffset: readonly [number, number],
  enabled = true,
) {
  const isEnabled = useRef(enabled)
  useEffect(() => {
    isEnabled.current = enabled
  }, [enabled])

  const [offset, setOffset] = useState(initialOffset)
  const [isDragging, setIsDragging] = useState(false)

  const offsetRef = useRef(initialOffset)
  useEffect(() => {
    offsetRef.current = offset
  }, [offset])

  useEffect(() => {
    let isMouseDown = false
    let startOffsetX: number
    let startOffsetY: number
    let currentX: number
    let currentY: number

    const handleMouseDown = (event: MouseEvent) => {
      isMouseDown = true
      startOffsetX = event.offsetX
      startOffsetY = event.offsetY
      currentX = offsetRef.current[0]
      currentY = offsetRef.current[1]
      setIsDragging(true)
    }

    const handleMouseMove = (event: MouseEvent) => {
      if (isEnabled.current && canvas && isMouseDown) {
        const deltaX = getRelativeDelta(event.offsetX - startOffsetX, canvas.width, canvas.clientWidth)
        const deltaY = getRelativeDelta(event.offsetY - startOffsetY, canvas.height, canvas.clientHeight)
        setOffset([currentX + deltaX, currentY + deltaY])
      }
    }

    const handleMouseUp = () => {
      isMouseDown = false
      setIsDragging(false)
    }

    const handleMouseLeave = () => {
      isMouseDown = false
      setIsDragging(false)
    }

    if (canvas) {
      canvas.addEventListener('mousedown', handleMouseDown)
      canvas.addEventListener('mousemove', handleMouseMove)
      canvas.addEventListener('mouseup', handleMouseUp)
      canvas.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mousedown', handleMouseDown)
        canvas.removeEventListener('mousemove', handleMouseMove)
        canvas.removeEventListener('mouseup', handleMouseUp)
        canvas.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [canvas])

  const controls: ImageMoveControls = {
    up: {
      direction: 'up',
      disabled: !enabled || isDragging || offset[1] === -maxOffset[1],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: () => setOffset([offset[0], offset[1] - getRelativeDelta(10, canvas!.height, canvas!.clientHeight)]),
    },
    down: {
      direction: 'down',
      disabled: !enabled || isDragging || offset[1] === maxOffset[1],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: () => setOffset([offset[0], offset[1] + getRelativeDelta(10, canvas!.height, canvas!.clientHeight)]),
    },
    left: {
      direction: 'left',
      disabled: !enabled || isDragging || offset[0] === -maxOffset[0],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: () => setOffset([offset[0] - getRelativeDelta(10, canvas!.width, canvas!.clientWidth), offset[1]]),
    },
    right: {
      direction: 'right',
      disabled: !enabled || isDragging || offset[0] === maxOffset[0],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: () => setOffset([offset[0] + getRelativeDelta(10, canvas!.width, canvas!.clientWidth), offset[1]]),
    },
  }

  const canDrag =
    enabled &&
    (offset[0] !== maxOffset[0] ||
      offset[0] !== -maxOffset[0] ||
      offset[1] !== maxOffset[1] ||
      offset[1] !== -maxOffset[1])

  return [offset, setOffset, controls, canDrag, isDragging] as const
}
