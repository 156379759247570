import { useRef, useState } from 'react'
import Immutable from 'immutable'
import loadable from '@loadable/component'

import { extractGoogleMapsEmbedded } from './extractGoogleMapsSource'
import { useTranslate } from '../../../../../utils/translate'

/* istanbul ignore next */
const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
/* istanbul ignore next */
const GoogleMapsSettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './GoogleMapsSettings'))

export default function GoogleMapsPlugin({
  editorView,
  editorMode,
  data = Immutable.fromJS({ iframe: '' }),
  onDataChange,
  onSave,
  onCancel,
  onEdit,
}: Readonly<WorkspacePluginProps>) {
  const [isSettingActive, setIsSettingActive] = useState(false)

  const t = useTranslate('interface')

  const handlePluginActiveStateChange = (isActive) => {
    setIsSettingActive(isActive)
  }

  const rootElement = useRef(null)

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={rootElement.current}
        placement="right"
        onActiveStateChange={handlePluginActiveStateChange}
        onEscapeKeyDown={onCancel}
      >
        {({ renderLayout }) => <GoogleMapsSettingsForm {...{ data, onDataChange, onSave, onCancel, renderLayout }} />}
      </SettingsLayer>
    )
  }

  const renderGoogleMaps = (pb) => {
    return (
      <div className="dali-plugin-maps-container">
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          className="dali-plugin-maps-content lazyload"
          data-src={`https://www.google.com/maps/embed?pb=${pb}`}
          // Suppress hydration warning because some prop values will
          // unavoidably be different between the server and the client.
          // lazysizes will change these attributes on the client.
          suppressHydrationWarning
          allowFullScreen
        />
      </div>
    )
  }

  const isEmpty = !data.get('iframe')
  const googleMapsPb = extractGoogleMapsEmbedded(data.get('iframe'))
  const googleMapsPlaceholder = (
    <div className="dali-grid-element-placeholder">
      <span className="dali-plugin-maps-placeholder" />
      <button className="dali-plugin-maps-placeholder-button-add" onClick={onEdit as any}>
        {t('components.gmapsElementComponent.addMapButton.label')}
      </button>
    </div>
  )

  if (editorView) {
    return (
      <div className={`dali-plugin-maps ${isSettingActive ? 'dali-grid-element-highlighted' : ''}`} ref={rootElement}>
        {isEmpty ? googleMapsPlaceholder : renderGoogleMaps(googleMapsPb)}
        {editorMode === 'edit' ? renderSettingsLayer() : null}
      </div>
    )
  }
  return !googleMapsPb ? null : <div className="dali-plugin-maps">{renderGoogleMaps(googleMapsPb)}</div>
}

GoogleMapsPlugin.actionBarButtons = {
  save: false,
  edit: true,
}
