import Immutable from 'immutable'

import * as actions from '../actions'

export const saveStatusActions = [
  'SET_THEME_SETTINGS',
  'SET_USER_SETTINGS',
  'CREATE_PAGE',
  'DUPLICATE_PAGE',
  'DELETE_PAGE',
  'UPDATE_PAGE_CONTENT',
  'UPDATE_CATEGORY_CONTENT',
  'UPDATE_LEGAL_PAGE_CONTENT',
  'UPDATE_CATEGORY_SETTINGS',
  'UPDATE_PAGE_SETTINGS',
  'UPDATE_NAVIGATION',
  'SWITCH_THEME',
]

export default function view(state: State, action: actions.AllActionTypes): State {
  switch (action.type) {
    case actions.SHOW_NOTIFICATION:
      return state.updateIn(['view', 'notifications'], (ns) =>
        ns.push(
          Immutable.fromJS({
            id: action.id,
            level: action.level,
            message: action.message,
          }),
        ),
      )
    case actions.HIDE_NOTIFICATION:
      return state.updateIn(['view', 'notifications'], (ns) => ns.filter((e) => e.get('id') !== action.id))
    case actions.SET_VIEW_ERROR:
      return state.setIn(
        ['view', 'error'],
        Immutable.fromJS({
          message: action.message,
          statusCode: action.statusCode,
          requestId: action.requestId,
        }),
      )
    case actions.CLEAR_VIEW_ERROR:
      return state.setIn(['view', 'error'], undefined)
    case actions.SET_MBO_MENU:
      return state.setIn(['view', 'mboMenu'], action.mboMenu)
    case actions.SET_MBO_MENU_MODE:
      return state.setIn(['view', 'mboMenuMode'], action.mboMenuMode)
    case actions.SET_IS_THEME_PREVIEW:
      return state.setIn(['view', 'isThemePreview'], action.isThemePreview)
    case actions.SET_IS_UNSUPPORTED_BROWSER:
      return state.setIn(['view', 'isUnsupportedBrowser'], action.isUnsupportedBrowser)
    case actions.SET_HAS_THEME_LOADED:
      return state.setIn(['view', 'hasThemeLoaded'], action.hasThemeLoaded)
    case actions.ACTIVATE_EDITOR_SIDEBAR_MODULE:
      return state.setIn(['view', 'editorSidebar', 'activeModule'], action.moduleName)
    case actions.SET_INTERFACE_LANGUAGE: {
      const pathname = state.getIn(['location', 'pathname'])

      return state.setIn(
        ['view', 'interfaceLanguage'],
        // only set Interface language for actual interfaces (can be removed once the SF gets interfacy things)
        pathname.startsWith('/editor') ? action.language : undefined,
      )
    }
    case actions.SET_LANGUAGE_SELECT_TRANSLATIONS:
      return state.setIn(['view', 'languageSelectTranslations'], Immutable.fromJS(action.translations))

    case 'HASHED_THEME_ASSETS':
      return state.setIn(['view', 'hashedThemeAssets'], Immutable.fromJS(action.hashedThemeAssets))
    case actions.SET_VIEW_BUSY_STATE:
      return state.setIn(['view', 'busy'], action.busyState)
    case actions.SET_THEME_NOTIFICATION:
      return state.setIn(['view', 'themeNotification'], Immutable.fromJS(action.notification))

    case actions.UPDATE_SUGGESTED_SEARCH:
      return state
        .setIn(['view', 'suggestedSearch', action.namespace, 'results'], action.results)
        .setIn(['view', 'suggestedSearch', action.namespace, 'searchTerm'], action.searchTerm)
    case actions.UPDATE_SUGGESTED_SEARCH_SELECTION:
      return state.setIn(['view', 'suggestedSearch', action.namespace, 'selected'], action.selected)
    case actions.DECLINE_COOKIE_CONSENT:
      return state.set('cookieConsent', false)
    default:
      if (saveStatusActions.includes(action.type.replace(/_(SUCCESS|FAILURE)$/, ''))) {
        if (action.type.endsWith('_SUCCESS')) {
          return state.setIn(['view', 'saveStatus'], 'saved')
        }

        if (action.type.endsWith('_FAILURE')) {
          return state.deleteIn(['view', 'saveStatus'])
        }

        return state.setIn(['view', 'saveStatus'], 'saving')
      }

      return state
  }
}
