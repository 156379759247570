import jwtDecode from '../../../utils/jwtDecode'

export type CustomerActionTypes =
  | ResolvedApiAction<SignupCustomer>
  | ResolvedApiAction<LoginCustomer>
  | ResolvedApiAction<LogoutCustomer>
  | ResolvedApiAction<RefreshCustomerLogin>
  | ResolvedApiAction<RequestPasswordReset>
  | ResolvedApiAction<ResetPassword>
  | ResolvedApiAction<RequestEmailChange>
  | ResolvedApiAction<ConfirmEmailChange>
  | ResolvedApiAction<ListOrders>
  | ResolvedApiAction<DeleteProfile>
  | ResolvedApiAction<GetCustomerDetails>
  | ResolvedApiAction<UpdateCustomerBillingAddress>
  | ResolvedApiAction<UpdateCustomerShippingAddress>

type GetCustomerDetails = {
  type: typeof GET_CUSTOMER_DETAILS
  response: Core.Customer
}
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const GET_CUSTOMER_DETAILS_SUCCESS = 'GET_CUSTOMER_DETAILS_SUCCESS'
export const GET_CUSTOMER_DETAILS_FAILURE = 'GET_CUSTOMER_DETAILS_FAILURE'

export function getCustomerDetails(options?: ActionOptions): ApiAction<GetCustomerDetails> {
  return {
    type: GET_CUSTOMER_DETAILS,
    callApi: (api) =>
      api.get('/api/v2/customer').then((res) => {
        return res.data
      }),
    options,
  }
}

type SignupCustomer = {
  type: typeof SIGNUP_CUSTOMER
  payload: {
    firstName: string
    lastName: string
    email: string
    initialPassword: string
  }
  response: { exp: number }
}
export const SIGNUP_CUSTOMER = 'SIGNUP_CUSTOMER'
export const SIGNUP_CUSTOMER_SUCCESS = 'SIGNUP_CUSTOMER_SUCCESS'
export const SIGNUP_CUSTOMER_FAILURE = 'SIGNUP_CUSTOMER_FAILURE'

export function signupCustomer(payload: SignupCustomer['payload'], options?: ActionOptions): ApiAction<SignupCustomer> {
  return {
    type: SIGNUP_CUSTOMER,
    payload,
    callApi: async (api, { firstName, lastName, email, initialPassword }, { locale }) => {
      const { data } = await api.post(
        `/api/v2/customer`,
        {
          firstName,
          lastName,
          email,
          initialPassword,
        },
        { params: { locale } },
      )
      return data
    },
    options,
  }
}

type LoginCustomer = {
  type: typeof LOGIN_CUSTOMER
  payload: {
    email: string
    password: string
  }
  response: { exp: number }
}
export const LOGIN_CUSTOMER = 'LOGIN_CUSTOMER'
export const LOGIN_CUSTOMER_SUCCESS = 'LOGIN_CUSTOMER_SUCCESS'
export const LOGIN_CUSTOMER_FAILURE = 'LOGIN_CUSTOMER_FAILURE'

export function loginCustomer(payload: LoginCustomer['payload'], options?: ActionOptions): ApiAction<LoginCustomer> {
  return {
    type: LOGIN_CUSTOMER,
    payload,
    callApi: async (api, { email, password }) => {
      const { data } = await api.post(`/api/v2/customer/login`, { email, password })
      return data
    },
    options,
  }
}

type RefreshCustomerLogin = {
  type: typeof REFRESH_CUSTOMER_LOGIN
  response: { exp: number }
}
export const REFRESH_CUSTOMER_LOGIN = 'REFRESH_CUSTOMER_LOGIN'
export const REFRESH_CUSTOMER_LOGIN_SUCCESS = 'REFRESH_CUSTOMER_LOGIN_SUCCESS'
export const REFRESH_CUSTOMER_LOGIN_FAILURE = 'REFRESH_CUSTOMER_LOGIN_FAILURE'
export function refreshCustomerLogin(options?: ActionOptions): ApiAction<RefreshCustomerLogin> {
  return {
    type: REFRESH_CUSTOMER_LOGIN,
    callApi: async (api) => {
      const { data } = await api.post(`/api/v2/customer/refresh-auth`)
      return data
    },
    options,
  }
}

type LogoutCustomer = {
  type: typeof LOGOUT_CUSTOMER
  response: undefined
}
export const LOGOUT_CUSTOMER = 'LOGOUT_CUSTOMER'
export const LOGOUT_CUSTOMER_SUCCESS = 'LOGOUT_CUSTOMER_SUCCESS'
export const LOGOUT_CUSTOMER_FAILURE = 'LOGOUT_CUSTOMER_FAILURE'

export function logoutCustomer(options?: ActionOptions): ApiAction<LogoutCustomer> {
  return {
    type: LOGOUT_CUSTOMER,
    callApi: async (api) => {
      await api.post(`/api/v2/customer/logout`)
    },
    options,
  }
}

type RequestPasswordReset = {
  type: typeof REQUEST_PASSWORD_RESET
  payload: string
}
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS'
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE'

export function requestPasswordReset(email: string, options?: ActionOptions): ApiAction<RequestPasswordReset> {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: email,
    callApi: (api, email, { locale }) =>
      api.post(`/api/v2/customer/reset-password-request`, { email }, { params: { locale } }),
    options,
  }
}

type ResetPassword = {
  type: typeof RESET_PASSWORD
  payload: {
    token: string
    newPassword: string
  }
}
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

export function resetPassword(payload: ResetPassword['payload'], options?: ActionOptions): ApiAction<ResetPassword> {
  return {
    type: RESET_PASSWORD,
    payload,
    callApi: (api, { token, newPassword }, { locale }) =>
      api.post(`/api/v2/customer/reset-password`, { token, newPassword }, { params: { locale } }),
    options,
  }
}

type ChangePassword = {
  type: typeof CHANGE_PASSWORD
  payload: {
    currentPassword: string
    newPassword: string
  }
}
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export function changePassword(payload: ChangePassword['payload'], options?: ActionOptions): ApiAction<ChangePassword> {
  return {
    type: CHANGE_PASSWORD,
    payload,
    callApi: (api, { currentPassword, newPassword }, { locale }) =>
      api.post(`/api/v2/customer/change-password`, { currentPassword, newPassword }, { params: { locale } }),
    options,
  }
}

type RequestEmailChange = {
  type: typeof REQUEST_EMAIL_CHANGE
  payload: {
    currentPassword: string
    newEmail: string
  }
  response: string
}
export const REQUEST_EMAIL_CHANGE = 'REQUEST_EMAIL_CHANGE'
export const REQUEST_EMAIL_CHANGE_SUCCESS = 'REQUEST_EMAIL_CHANGE_SUCCESS'
export const REQUEST_EMAIL_CHANGE_FAILURE = 'REQUEST_EMAIL_CHANGE_FAILURE'

export function requestEmailChange(
  payload: RequestEmailChange['payload'],
  options?: ActionOptions,
): ApiAction<RequestEmailChange> {
  return {
    type: REQUEST_EMAIL_CHANGE,
    payload,
    callApi: async (api, { currentPassword, newEmail }, { locale }) => {
      await api.post(`/api/v2/customer/change-email-request`, { currentPassword, newEmail }, { params: { locale } })
      return newEmail
    },
    options,
  }
}
type ConfirmEmailChange = {
  type: typeof CONFIRM_EMAIL_CHANGE
  payload: string
  response: string
  errorMessage?: string
}

export const CONFIRM_EMAIL_CHANGE = 'CONFIRM_EMAIL_CHANGE'
export const CONFIRM_EMAIL_CHANGE_SUCCESS = 'CONFIRM_EMAIL_CHANGE_SUCCESS'
export const CONFIRM_EMAIL_CHANGE_FAILURE = 'CONFIRM_EMAIL_CHANGE_FAILURE'

export function confirmEmailChange(
  payload: ConfirmEmailChange['payload'],
  options?: ActionOptions,
): ApiAction<ConfirmEmailChange> {
  return {
    type: CONFIRM_EMAIL_CHANGE,
    payload,
    callApi: async (api, token, { locale }) => {
      await api.post(`/api/v2/customer/confirm-email-change`, { token }, { params: { locale } })
      return jwtDecode(token).newEmail
    },
    options,
  }
}

type ListOrders = {
  type: typeof LIST_ORDERS
  payload: {
    page: number
    size: number
  }
  response: Core.PageableCustomerOrderResult
}
export const LIST_ORDERS = 'LIST_ORDERS'
export const LIST_ORDERS_SUCCESS = 'LIST_ORDERS_SUCCESS'
export const LIST_ORDERS_FAILURE = 'LIST_ORDERS_FAILURE'

export function listOrders(payload: ListOrders['payload'], options?: ActionOptions): ApiAction<ListOrders> {
  return {
    type: LIST_ORDERS,
    payload,
    callApi: (api, { page, size }, { locale }) =>
      api.get(`/api/v2/customer/orders?page=${page}&size=${size}`, { params: { locale } }).then((res) => {
        return res.data
      }),
    options,
  }
}

type DeleteProfile = {
  type: typeof DELETE_CUSTOMER
}

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE'

export function deleteCustomer(): ApiAction<DeleteProfile> {
  return {
    type: DELETE_CUSTOMER,
    callApi: (api, _payload, { locale }) => api.delete(`/api/v2/customer`, { params: { locale } }),
  }
}

type UpdateCustomerBillingAddress = {
  type: typeof UPDATE_CUSTOMER_BILLING_ADDRESS
  payload: Core.Customer['billingAddress']
  response: Core.Customer['billingAddress']
}
export const UPDATE_CUSTOMER_BILLING_ADDRESS = 'UPDATE_CUSTOMER_BILLING_ADDRESS'
export const UPDATE_CUSTOMER_BILLING_ADDRESS_SUCCESS = 'UPDATE_CUSTOMER_BILLING_ADDRESS_SUCCESS'
export const UPDATE_CUSTOMER_BILLING_ADDRESS_FAILURE = 'UPDATE_CUSTOMER_BILLING_ADDRESS_FAILURE'

export function updateCustomerBillingAddress(
  payload: UpdateCustomerBillingAddress['payload'],
  options?: ActionOptions,
): ApiAction<UpdateCustomerBillingAddress> {
  return {
    type: UPDATE_CUSTOMER_BILLING_ADDRESS,
    payload,
    callApi: (api, payload, { locale }) =>
      api.put('/api/v2/customer/billing-address', payload, { params: { locale } }).then((res) => {
        return res.data
      }),
    options,
  }
}

type UpdateCustomerShippingAddress = {
  type: typeof UPDATE_CUSTOMER_SHIPPING_ADDRESS
  payload: Core.Customer['shippingAddress']
  response: Core.Customer['shippingAddress']
}
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS = 'UPDATE_CUSTOMER_SHIPPING_ADDRESS'
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_CUSTOMER_SHIPPING_ADDRESS_SUCCESS'
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS_FAILURE = 'UPDATE_CUSTOMER_SHIPPING_ADDRESS_FAILURE'

export function updateCustomerShippingAddress(
  payload: UpdateCustomerShippingAddress['payload'],
  options?: ActionOptions,
): ApiAction<UpdateCustomerShippingAddress> {
  return {
    type: UPDATE_CUSTOMER_SHIPPING_ADDRESS,
    payload,
    callApi: (api, payload, { locale }) =>
      api.put('/api/v2/customer/shipping-address', payload, { params: { locale } }).then((res) => {
        return res.data
      }),
    options,
  }
}
