import { Component } from 'react'
import { bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'

import { DocumentMeta } from '../components/DocumentMeta'
import { PageOrCategoryContext } from './Category'
import { loadLegalPage } from '../store/actions'
import CookieConsent from '../components/CookieConsent'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

class LegalPage extends Component {
  static propTypes = {
    isEditor: bool.isRequired,
    t: func.isRequired,
    viewError: map,
    shop: map,
    page: map,
  }

  static storeUpdate = (props, state) => {
    const isForwardNavigation = props.location.action === 'PUSH'
    const updates = []
    if (isForwardNavigation || !state.getIn(['legalPagesContents', props.params.legalPageSlug])) {
      updates.push(loadLegalPage(props.params.legalPageSlug))
    }
    return updates
  }

  static contentCreationDisabled = () => true

  render() {
    const { shop, page, viewError, isEditor, t } = this.props

    if (viewError || !page) {
      return (
        <>
          <DocumentMeta />
          <Theme withLayout error={viewError} currentView="legal" />
        </>
      )
    }

    const cookieNoticeSettings = shop.getIn(['userSettings', 'cookieNotice'])
    const showCookieConsentPreview = Boolean(
      cookieNoticeSettings && cookieNoticeSettings.get('active') && page.get('slug') === 'cookiepolicy',
    )

    const isBeyondShop = Boolean(shop.get('beyond'))
    const pageTitle = isBeyondShop ? t(page.get('title')) : page.get('title')

    return (
      <PageOrCategoryContext.Provider value={this.props.page}>
        <DocumentMeta
          title={`${pageTitle} - ${shop.get('title')}`}
          meta={[{ name: 'robots', content: 'noindex, follow' }]}
        />
        <Theme withLayout currentView="legal">
          {(renderView, props) => (
            <>
              {renderView('Legal', {
                ...props,
                page: page.toJS(),
              })}
              {showCookieConsentPreview && (
                <section className="section">
                  <CookieConsent inline isEditor={isEditor} />
                </section>
              )}
            </>
          )}
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state, props) => ({
    shop: state.get('shop'),
    viewError: state.getIn(['view', 'error']),
    navigation: state.get('navigation'),
    breadcrumb: state.get('breadcrumb'),
    page: state.get('legalPagesContents').get(props.params.legalPageSlug),
    isEditor: Boolean(state.getIn(['view', 'editorMode'])),
  })),
)(LegalPage)
