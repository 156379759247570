import type { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { useTranslate } from '../utils/translate'

// This `ErrorPage` component renders a "blank" error page without navigation, footer and any theme dependent styling.
// Up until now, it is only used for 429 rate limit errors. See EPUI-861 and related PR for explanation.
// This is NOT TO BE CONFUSED WITH the `Error` component which renders "normal" error pages for all non 429 errors
// as part of a shop's page with navigation, footer and theme dependent styling.
export default function ErrorPage(): ReactElement {
  const error = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'error']))
  const shop = useSelector<State, ImmutableMap>((state) => state.get('shop'))
  const t = useTranslate('shop')

  return (
    <div className="error-page">
      <section className="section">
        <div className="wrapper">
          <div className="content">
            <h1>{t('views.storefrontView.errorPageSection.errorcode', { code: error.get('statusCode') })}</h1>
            <div className="error-page-info">
              <h3>{t('views.storefrontView.errorPageSection.error500')}</h3>
              {error.get('requestId') && (
                <p className="error-page-info-explanation">
                  {t('views.storefrontView.errorPageSection.idExplanation')} <code>{error.get('requestId')}</code>
                </p>
              )}
              <p>
                {/* If this error page is rendered, the website cannot be used. Therefore, navigate with full page loads. */}
                <a href={shop.get('href')} className="error-page-info-link">
                  {t('views.storefrontView.returnToStartpageLink.label')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
