import { useCallback, useEffect, useRef, useState, useTransition } from 'react'

import { EditorSpotlight } from '../EditorSpotlight'
import { ImageCanvas } from './ImageCanvas'
import { ImageEditorMenu } from './ImageEditorMenu'
import { ImageMoveControls } from './ImageMoveControls'
import { getErrorReasonKey, getFileFromCanvas, loadFile } from './ImageEditor.helpers'
import { useImage } from './useImage'
import { useImageOffset } from './useImageOffset'
import { useLoadingIndicator } from './useLoadingIndicator'
import { useTranslate } from '../../utils/translate'
import { useWheelZoom } from './useWheelZoom'
import useHelpCenterLink from '../../utils/hooks/useHelpCenterLink'

type ImageEditorProps = Readonly<{
  src: string
  previewSrc?: string
  aspectRatioMap: AspectRatioMap
  edit: EditState
  initial: boolean
  onInit: ChangeHandler
  onChange: ChangeHandler
  onCancel: () => void
}>

export type AspectRatioLabel = '1:1' | '2:3' | '3:1' | '3:2' | '4:3' | '5:1' | '16:9' | 'circle' | 'original'
type AspectRatioMap = Partial<Record<'1:1' | '2:3' | '3:1' | '3:2' | '4:3' | '5:1' | '16:9' | 'circle', number>>
type ErrorMessage = `${'couldNotUseImage' | 'couldNotSaveImage'}:${ReturnType<typeof getErrorReasonKey>}` | null

type EditState = Readonly<{
  aspectRatio: { label: AspectRatioLabel; value?: number }
  offset: readonly [number, number]
  zoom: number
}>

type ChangeHandler = (edit: EditState, imageFile: File, newImageSourceFile?: File) => Promise<void>

/**
 * Image editor component that is used in image content elements, allowing
 * users to control the image display format and portion of the image that
 * should be displayed.
 *
 * @param src Source URL for the image to be edited.
 * @param previewSrc Optional preview image URL that is used to display a
 * placeholder while the source image is loading.
 * @param aspectRatioMap The aspect ratio options to choose from in the editor.
 * @param edit The edit state (aspect ratio, offset, zoom factor).
 * @param initial Boolean indicating whether the src was never edited before.
 * @param onInit Callback to handle the initial render, e.g. initial auto-edit.
 * @param onChange Callback to handle changes to the image, i.e. save.
 * @param onCancel Callback to handle canceling the editing.
 */
export function ImageEditor({
  src,
  previewSrc,
  aspectRatioMap,
  edit,
  initial,
  onInit,
  onChange,
  onCancel,
}: ImageEditorProps) {
  const [isPending, startTransition] = useTransition()
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [canvasContainer, setCanvasContainer] = useState<HTMLDivElement | null>(null)
  const hasInitialized = useRef(false)
  const isEditingEnabled = hasInitialized.current && !isPending
  const t = useTranslate('interface', 'components.imageEditorComponent')

  // Image edit state
  const [zoomFactor, setZoomFactor] = useState(edit.zoom)
  const [aspectRatio, setAspectRatio] = useState(edit.aspectRatio)
  const [maxImageOffset, setMaxImageOffset] = useState<readonly [number, number]>([0, 0])
  const [imageOffset, setImageOffset, controls, canDrag, isDragging] = useImageOffset(
    canvas,
    edit.offset,
    maxImageOffset,
    isEditingEnabled,
  )
  useWheelZoom(canvasContainer, setZoomFactor, isEditingEnabled)

  // Handle the original edit state differently to preserve animated GIFs:
  // - For `onInit` and `onChange`, use the source file to retain all frames.
  //   For other edits, use the canvas to get the modified image.
  // - Display the original image with `<img>` to show the animation in the editor.
  const hasOriginalEdit =
    aspectRatio.label === 'original' && zoomFactor === 1 && imageOffset[0] === 0 && imageOffset[1] === 0

  // Error state (reset whenever a change is made)
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [errorKey, errorReason] = errorMessage?.split(':') ?? []
  useEffect(() => {
    setErrorMessage(null)
  }, [aspectRatio, imageOffset, zoomFactor])

  // Help center link for image format errors
  const helpCenterUrl = useHelpCenterLink('IMAGE_FORMATS')
  const showHelpCenterLink = errorReason === 'unsupportedMediaType'

  // Loading spinner
  const [showLoadingSpinner, setLoadingPromise] = useLoadingIndicator()

  // Source image
  const [hasPendingFileChange, setHasPendingFileChange] = useState(false)
  const handleSourceImageLoad = useCallback(
    (image: HTMLImageElement, file: File) => {
      setAspectRatio((currentAspectRatio) => {
        // Use the image’s aspect ratio if the current chosen aspect ratio is "original",
        // or if it is a new chosen GIF image file (so that the animation is preserved).
        // Otherwise, keep the current chosen aspect ratio.
        const isNewGif = file.type === 'image/gif' && (initial || hasPendingFileChange)
        return currentAspectRatio.label === 'original' || isNewGif
          ? { label: 'original', value: image.width / image.height }
          : currentAspectRatio
      })
    },
    [initial, hasPendingFileChange],
  )
  const [sourceImage, sourceFile, loadSourceImage] = useImage(handleSourceImageLoad)
  useEffect(() => {
    const loadingSourceImage = loadFile(src).then(loadSourceImage)
    setLoadingPromise(loadingSourceImage)
    startTransition(async () => {
      await loadingSourceImage
    })
  }, [src, loadSourceImage, setLoadingPromise])

  const handleFileChange = (file: File) => {
    setHasPendingFileChange(true)
    const loadingSourceImage = loadSourceImage(file).then(
      () => {
        setZoomFactor(1)
        setImageOffset([0, 0])
      },
      (exception) => {
        setHasPendingFileChange(false)
        setErrorMessage(`couldNotUseImage:${getErrorReasonKey(exception)}`)
      },
    )
    setLoadingPromise(loadingSourceImage)
    startTransition(async () => {
      await loadingSourceImage
    })
  }

  const [isSaving, setIsSaving] = useState(false)
  const handleChange = useCallback(
    (handler: ChangeHandler, fromSourceFile = false) => {
      if (canvas && sourceFile) {
        const handlingChange = (
          fromSourceFile ? Promise.resolve(sourceFile) : getFileFromCanvas(canvas, sourceFile)
        ).then(
          async (imageFile) => {
            const currentEdit: EditState = { aspectRatio, offset: imageOffset, zoom: zoomFactor }
            const newSourceFile = hasPendingFileChange ? sourceFile : undefined
            setIsSaving(true)
            try {
              await handler(currentEdit, imageFile, newSourceFile)
              setHasPendingFileChange(false)
            } catch (exception) {
              setErrorMessage(`couldNotSaveImage:${getErrorReasonKey(exception)}`)
            } finally {
              setIsSaving(false)
            }
          },
          (exception) => {
            setErrorMessage(`couldNotSaveImage:${getErrorReasonKey(exception)}`)
          },
        )
        setLoadingPromise(handlingChange)
        startTransition(async () => {
          await handlingChange
        })
      }
    },
    [canvas, sourceFile, setLoadingPromise, hasPendingFileChange, aspectRatio, imageOffset, zoomFactor],
  )

  useEffect(() => {
    if (!hasInitialized.current && canvas && sourceFile) {
      hasInitialized.current = true
      handleChange(onInit, hasOriginalEdit)
    }
  }, [handleChange, onInit, canvas, sourceFile, hasOriginalEdit])

  return (
    <EditorSpotlight onCancel={onCancel} title={t('spotlightDialog.accessibilityLabel')}>
      <span role="status" className="visually-hidden">
        {t(`editorStatus.${isEditingEnabled ? 'ready' : isSaving ? 'saving' : 'loading'}.accessibilityLabel`)}
      </span>
      <div
        className="ep-image-editor"
        aria-busy={!isEditingEnabled}
        data-can-drag={canDrag || null}
        data-is-dragging={isDragging || null}
        data-is-saving={isSaving || null}
      >
        {errorKey && errorReason && (
          <div className="ep-notification-danger" role="alert">
            {t(`errorMessages.${errorKey}`, {
              reason: t(`errorMessages.reasons.${errorReason}`, { maxMbFileSize: 20 }),
            })}
            {showHelpCenterLink && (
              <a href={helpCenterUrl} target="_blank" className="ep-form-row-text-external-link" rel="noreferrer">
                {t('errorMessages.helpCenterLink')}
              </a>
            )}
          </div>
        )}
        <ImageEditorMenu
          t={t}
          referenceElement={canvasContainer}
          disabled={!isEditingEnabled}
          aspectRatio={aspectRatio}
          aspectRatioOptions={{
            ...aspectRatioMap,
            original: sourceImage ? sourceImage.width / sourceImage.height : undefined,
          }}
          zoomFactor={zoomFactor}
          onZoomFactorChange={setZoomFactor}
          onAspectRatioChange={setAspectRatio}
          onFileChange={handleFileChange}
          onCancel={onCancel}
          onSave={() => handleChange(onChange, hasOriginalEdit)}
        />
        <ImageMoveControls controls={controls} t={t} />
        <div
          ref={setCanvasContainer}
          style={{
            aspectRatio: aspectRatio.value,
            clipPath: aspectRatio.label === 'circle' ? 'circle(50% at center)' : '',
          }}
        >
          {sourceImage && (
            <div style={{ display: hasOriginalEdit ? 'none' : 'block' }}>
              <ImageCanvas
                canvasRef={setCanvas}
                image={sourceImage}
                aspectRatio={aspectRatio.value}
                zoomFactor={zoomFactor}
                offset={imageOffset}
                onUpdate={(offset, maxOffset) => {
                  // ImageCanvas returns the offset that was used to draw the image. It
                  // is based on the offset that was passed in but might differ to keep
                  // the image within the bounds of the canvas. Update the offset state
                  // with the used offset if it differs.
                  if (imageOffset[0] !== offset[0] || imageOffset[1] !== offset[1]) {
                    setImageOffset(offset)
                  }

                  // For button state management
                  if (maxImageOffset[0] !== maxOffset[0] || maxImageOffset[1] !== maxOffset[1]) {
                    setMaxImageOffset(maxOffset)
                  }
                }}
              />
            </div>
          )}
          {sourceImage && hasOriginalEdit && <img src={sourceImage.src} style={{ width: '100%' }} alt="" />}
          {showLoadingSpinner && <span className="ep-image-editor-loading-spinner" />}
          {!sourceImage && previewSrc && (
            <img className="ep-image-editor-loading-preview-image" src={previewSrc} alt="" />
          )}
        </div>
      </div>
    </EditorSpotlight>
  )
}
