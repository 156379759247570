import type { ReactElement } from 'react'
import { fromJS } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'
import { useRef, useState } from 'react'
import cc from 'classcat'
import loadable from '@loadable/component'

import {
  getLocationStringForGA,
  sendProductSliderImpressions,
  trackProductClick,
} from '../../../../../utils/googleAnalytics'
import { loadProductsByIds } from '../../../../../store/actions'
import { useTranslate } from '../../../../../utils/translate'
import EmptyProductListItem from '../../../EmptyProductListItem'
import ProductListItem from '../../../ProductListItem'
import ProductListSwiper from '../../../../ProductListSwiper'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ProductSliderSettings'))

export function ProductSliderPlugin({
  editorMode,
  onEdit,
  editorView,
  data = fromJS({}),
  config,
  onDataChange,
  onCancel,
  onSave,
}: Readonly<WorkspacePluginProps>): ReactElement | null {
  const products = useSelector<State, ImmutableList>((state) =>
    state.getIn(['productData', data.get('id'), 'products'], fromJS([])),
  )

  const locationPathname = useSelector<State, string>((state) => state.getIn(['location', 'pathname']))
  const sellingCountryId = useSelector<State, number>((state) => state.getIn(['shop', 'sellingCountryId'])) || null
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const t = useTranslate('interface')

  const [isSettingActive, setIsSettingActive] = useState(false)
  const [error, setError] = useState(false)

  const pluginActiveClasses = cc(['product-slider', { 'dali-grid-element-highlighted': isSettingActive }])

  const visibleProducts = (products.filter((product) => product?.get('isVisible')).toJS() as Core.Product[]).map(
    (product: Core.Product) => ({ ...product, isLoaded: true }),
  )
  const productList = { products: visibleProducts, totalNumberOfProducts: visibleProducts.length }

  if (error || (!editorView && !data.get('productIds').size)) {
    return null
  }

  // catch the error and handle it in the wrapper to prevent the enclosing jsx from being rendered
  // as if the initial load fails, we do not know how many slides to render and without switching slidegroups it is impossible to recover
  async function loadProducts() {
    try {
      await dispatch(
        loadProductsByIds(data.get('id'), Array.from(data.get('productIds')), sellingCountryId, {
          showErrorNotification: false,
        }),
      )
    } catch {
      setError(true)
    }
  }

  const renderProductItem = (product: Core.Product, index: number) => (
    <ProductListItem
      product={product as Frontend.Product}
      trackProductClick={trackProductClick([getLocationStringForGA(locationPathname), 'product-slider'])}
      productIndex={index}
      key={product.productId}
    />
  )

  return (
    <div className={pluginActiveClasses} ref={ref}>
      {editorMode === 'edit' && (
        <SettingsLayer
          referenceElement={ref.current}
          placement="top"
          onActiveStateChange={(newActiveState) => setIsSettingActive(newActiveState)}
          onEscapeKeyDown={(event) => event.target.tagName !== 'INPUT' && onCancel()}
          className="dali-settingslayer-productslider"
        >
          {({ renderLayout }) => (
            <SettingsForm
              {...{ data, config, onDataChange, onCancel, onSave, renderLayout, selectedProducts: products }}
            />
          )}
        </SettingsLayer>
      )}
      {editorView && !data.get('productIds').size ? (
        <div className="dali-grid-element-placeholder">
          <span className="dali-plugin-productslider-placeholder" />
          <button className="dali-plugin-productslider-placeholder-button-add" onClick={() => onEdit()}>
            {t('components.productSliderComponent.selectProductsButton.label')}
          </button>
        </div>
      ) : (
        <ProductListSwiper
          loadProducts={loadProducts}
          productList={visibleProducts.length > 0 ? productList : undefined}
          renderProductItem={renderProductItem}
          renderEmptyProductItem={(index) => <EmptyProductListItem key={'emptySlide_' + index} />}
          uniqueId={data.get('id')}
          breakpoints={['480px', '768px', '992px']}
          productSize={data.get('imageSize', 'S')}
          trackProductList={sendProductSliderImpressions(getLocationStringForGA(locationPathname))}
        />
      )}
    </div>
  )
}

ProductSliderPlugin.actionBarButtons = { save: false, edit: true }
