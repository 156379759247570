import { camelCase } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'

import { hideNotification } from '../store/actions'
import { useTranslate } from '../utils/translate'

export default function NotificationOverlay() {
  const notifications = useSelector<State, ImmutableList>((state) => state.getIn(['view', 'notifications']))
  const dispatch = useDispatch<GlobalDispatch>()
  const t = useTranslate('shop')

  return (
    <div className="ep-alert-overlay">
      {notifications
        .filter((n) => n?.get('level') === 'error')
        .map((err) => {
          if (!err) return null

          const errorMessage = t(`notifications.${camelCase(err.get('message'))}`)
          const needsErrorId = errorMessage.endsWith(t('notifications.idExplanation'))

          return (
            <div className="ep-alert-info" key={err.get('id')}>
              <span
                title={t('notifications.dismissButton.tooltip')}
                className="ep-alert-info-close"
                onClick={() => dispatch(hideNotification(err.get('id')))}
              />
              {errorMessage}
              {needsErrorId && <code> {err.get('serverRequestId')}</code>}
            </div>
          )
        })
        .toArray()}
    </div>
  )
}
