import type { ReactElement, ReactNode } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import cc from 'classcat'

type Props = {
  show: boolean
  closeOnBackdropClick?: boolean
  onClose: () => void
  lightboxClassName?: string
  fullscreen?: boolean
  children: ReactNode | (({ close }: { close: () => void }) => ReactNode)
}

let additionalBodyStyles: HTMLStyleElement | null

export default function Lightbox({
  show,
  closeOnBackdropClick,
  onClose,
  lightboxClassName,
  fullscreen,
  children,
}: Readonly<Props>): ReactElement | null {
  const backdrop = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    if (backdrop.current) {
      function handleTransitionEnd(event) {
        if (event.target === event.currentTarget) {
          backdrop.current?.removeEventListener('transitionend', handleTransitionEnd)
          onClose()
        }
      }
      backdrop.current.addEventListener('transitionend', handleTransitionEnd)
      setTransition(true)
    } else onClose()
  }, [onClose])

  // close on escape key, but not upon dismissing an autocomplete UI via escape
  useEffect(() => {
    if (!show) return

    let escapeKeyPresses = 0

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        if (event.target.tagName !== 'INPUT' || ++escapeKeyPresses > 1) handleClose()
      } else if (escapeKeyPresses) {
        escapeKeyPresses--
      }
    }
    window.addEventListener('keyup', handleEscapeKey, true)

    return () => {
      window.removeEventListener('keyup', handleEscapeKey, true)
    }
  }, [handleClose, show])

  // prevent body scrolling (https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/)
  // also, hide most 3rd party widgets (any child of the body element that is not #app)
  useEffect(() => {
    if (show) {
      const htmlElement = document.querySelector('html') as HTMLElement
      const hasVerticalScrollbar = htmlElement.clientHeight < htmlElement.scrollHeight

      additionalBodyStyles = document.createElement('style')
      additionalBodyStyles.innerHTML = `
        /* Lightbox.tsx */
        body {
          top: -${window.scrollY}px;
          width: 100%;
          position: fixed;
          ${
            // prevent scrollbar disappearing due to position: fixed
            hasVerticalScrollbar ? 'overflow-y: scroll;' : ''
          }
        }
        body > :not(#app) { display: none !important }
        ${'' /* reset transform set by preview bar styles to prevent wrong positioning */}
        .body { transform: initial }
      `
      document.head.append(additionalBodyStyles)
    }

    return () => {
      if (show) {
        const scrollY = getComputedStyle(document.body).top
        additionalBodyStyles?.remove()
        additionalBodyStyles = null
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }
  }, [show])

  const [transition, setTransition] = useState(true)
  useEffect(() => {
    setTransition(!show)
  }, [show])

  if (!show) return null

  return (
    <div
      ref={backdrop}
      className={cc(['lightbox-backdrop', { transition }])}
      {...(fullscreen && { 'data-lightbox-type': 'fullscreen' })}
      {...(closeOnBackdropClick ? { onClick: (event) => event.target === event.currentTarget && handleClose() } : {})}
    >
      <div className={cc(['lightbox', lightboxClassName])}>
        <div className="lightbox-close">
          <span className="lightbox-close-button" onClick={handleClose} />
        </div>
        <div className="lightbox-body">
          {typeof children === 'function' ? children({ close: handleClose }) : children}
        </div>
      </div>
    </div>
  )
}
