import { fromJS } from 'immutable'
import loadable from '@loadable/component'

import { withQueryAndScope } from './urlGenerators'
import Category from './views/Category'
import Home from './views/Home'
import LegalPage from './views/LegalPage'
import Order from './views/Order'
import Page from './views/Page'
import Search from './views/Search'
import Storefront from './views/Storefront'
import UnknownRoute from './views/UnknownRoute'

export const cartPath = 'cart'

export const orderPath = 'o'

const Account = loadable(() => import(/* webpackChunkName: "account" */ './views/Account'))
const Product = loadable(() => import(/* webpackChunkName: "Product" */ './views/Product'))
const Cart = loadable(() => import(/* webpackChunkName: "Cart" */ './views/Cart'))
const Checkout = loadable(() => import(/* webpackChunkName: "checkout" */ './views/Checkout'))
const ForgotPassword = loadable(() => import(/* webpackChunkName: "checkout" */ './views/ForgotPassword'))
const ResetPassword = loadable(() => import(/* webpackChunkName: "checkout" */ './views/ResetPassword'))
const Editor = loadable(() => import(/* webpackChunkName: "editor" */ './views/Editor'))
const Themes = loadable(() => import(/* webpackChunkName: "editor" */ './views/editor/Themes'))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const maybeRedirect = (scope, { pathname, search }, replace) => {
  // Nothing to redirect currently. Refer to Git history on how to use me.
}

const onChange = (scope) => (_, nextState, replace) => maybeRedirect(scope, nextState.location, replace)
const onEnter = (scope) => (state, replace) => maybeRedirect(scope, state.location, replace)

const unknownChildRoute = {
  path: '*',
  component: UnknownRoute,
  status: 404,
}

const childRoutes = [
  {
    path: 'p/:productId',
    component: Product,
  },
  {
    path: 'c/*',
    component: Category,
  },
  {
    path: 'i/:pageSlug',
    component: Page,
  },
  {
    path: 'l/:legalPageSlug',
    component: LegalPage,
  },
  {
    path: cartPath,
    component: Cart,
  },
  {
    path: `${orderPath}(/:orderId)`,
    component: Order,
  },
  {
    path: 'search',
    component: Search,
  },
  {
    path: 'checkout',
    component: Checkout,
  },
  {
    path: 'checkout/:stepId',
    component: Checkout,
  },
  {
    path: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: 'reset-password',
    component: ResetPassword,
  },
  {
    path: 'customer-account',
    component: Account,
  },
  {
    path: 'customer-account/:section',
    component: Account,
  },
  {
    path: 'home',
    component: Home,
    onEnter: (state, replace) => replace(withQueryAndScope('/', fromJS(state.location))),
  },
]

const editorOnlyChildRoutes = [
  {
    path: 'themes',
    component: Themes,
  },
]

/**
 * construct react router 3 routes for each locale of the shop
 * @param {{identifier: string, isVisible: boolean}[]} locales a list of the shop locales
 * @returns {any[]} react router 3 routes
 */
export default function getRoutes(locales) {
  // Extend the childRoutes with a locale childRoute wrapping all other childRoutes again.
  // With this all childRoutes are available as root path and below a localized path.
  // The fallback needs to always be after the last route, in this case the locale route.
  const localizedChildRoutes = childRoutes.concat(
    locales.map((locale) => ({
      path: locale.identifier.substr(0, 2),
      indexRoute: {
        component: Home,
      },
      childRoutes: childRoutes.concat([unknownChildRoute]),
    })),
    unknownChildRoute,
  )

  return [
    {
      path: '/editor', // this is hardcoded in App.jsx at `tell the MBO where we are (aka "See your site")`
      component: Editor,
      indexRoute: {
        component: Home,
      },
      onChange: onChange('/editor/'),
      onEnter: onEnter('/editor/'),
      childRoutes: editorOnlyChildRoutes.concat(localizedChildRoutes),
    },
    {
      path: '/',
      component: Storefront,
      indexRoute: {
        component: Home,
      },
      onChange: onChange('/'),
      onEnter: onEnter('/'),
      childRoutes: localizedChildRoutes,
    },
  ]
}
