import type { SyntheticEvent } from 'react'
import { createRef } from 'react'
import { useSelector } from 'react-redux'
import cc from 'classcat'

import SuggestedSearch, { ResultsList, SearchField } from '../../templateComponents/SuggestedSearch'
import { imageUrl } from '../../ThemeView'
import { useTranslate } from '../../../utils/translate'
import Link from '../../templateComponents/Link'

interface SearchForm extends Partial<HTMLElement> {
  namespace: string
  maxSuggestedItems?: number
  blurDelay?: number
  debug?: boolean
}

interface SuggestedSearchItem extends Frontend.Product {
  active: boolean
}

export default function SearchForm({
  blurDelay,
  debug = false,
  maxSuggestedItems,
  namespace,
  ...htmlAttributes
}: Readonly<SearchForm>) {
  const hashedThemeAssets = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'hashedThemeAssets']))
  const isEcommerceDisabled = useSelector<State, boolean>((state) =>
    Boolean(
      state
        .getIn(['shop', 'attributes'])
        .find(
          (attribute: ImmutableMap) =>
            attribute.get('name') === 'ecommerce:disabled' && attribute.get('value') === 'true',
        ),
    ),
  )
  const t = useTranslate('shop', 'components.productSearchComponent')

  if (isEcommerceDisabled) return null

  const submitButtonRef = createRef<HTMLButtonElement>()

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>, performSearch: (query: string) => void) {
    const form = event.currentTarget
    const searchField = form.q

    event.preventDefault()

    if (searchField.value) {
      performSearch(searchField.value)
      submitButtonRef.current?.blur()
    } else {
      searchField.focus()
    }
  }

  return (
    <SuggestedSearch
      {...htmlAttributes}
      namespace={namespace}
      autoComplete="off"
      action="/search"
      onSubmit={handleSubmit}
      blurDelay={blurDelay || (debug ? 600000 : null)}
    >
      <button type="submit" className="search-form-icon search-form-submit" ref={submitButtonRef}>
        <span>{t('submitButton.label')}</span>
      </button>
      <SearchField
        maxSuggestedItems={maxSuggestedItems}
        className="search-form-field"
        type="text"
        name="q"
        placeholder={t('searchInputField.placeholder')}
      />
      <ResultsList renderItems={renderSuggestedSearchItems} />
    </SuggestedSearch>
  )

  function renderSuggestedSearchItems(items: SuggestedSearchItem[]) {
    return (
      <div className="search-form-suggestions">
        <ul className="search-form-results">
          {items.map((item) => (
            <li
              className={cc([
                'search-form-results-item',
                {
                  active: item.active,
                },
              ])}
              key={item.productId}
            >
              <Link to={item.href}>
                <div
                  className="search-form-results-item-image"
                  style={{
                    backgroundImage: item.image
                      ? `url("${imageUrl(item.image.url, 64, 64)}")`
                      : `url('/themes/epages.base/assets/images/${hashedThemeAssets.getIn([
                          'epages.base/assets/images',
                          'placeholder_180.jpg',
                        ])}`,
                  }}
                />
                <span className="search-form-results-item-name">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
