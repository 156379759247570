import type { ReactElement } from 'react'

export default function EmptyProductListItem(): ReactElement {
  return (
    <div className="product-item">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="product-item-link">
        <div className="product-item-image-container">
          <div className="product-item-placeholder">
            <span className="product-item-placeholder-icon" />
          </div>
        </div>
        <div className="product-item-placeholder-text" />
      </a>
    </div>
  )
}
