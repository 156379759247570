import type { ReactElement } from 'react'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Immutable from 'immutable'
import InnerHTML from 'dangerously-set-html-content'
import loadable from '@loadable/component'

import { useTranslate } from '../../../../../utils/translate'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const FreeHtmlSettings = loadable(() => import(/* webpackChunkName: "editor" */ './FreeHtmlSettings'))

export default function FreeHtmlPlugin({
  editorView,
  editorMode,
  data = Immutable.fromJS({ html: '' }),
  onDataChange,
  onSave,
  onCancel,
  onEdit,
}: Readonly<WorkspacePluginProps>): ReactElement | null {
  const rootElement = useRef<HTMLDivElement>(null)
  const cookieConsent = useSelector<State, string>((state) => state.get('cookieConsent'))
  const [isSettingActive, setIsSettingActive] = useState(false)

  const t = useTranslate('interface')

  // A HTML "nonce" attribute is needed for inline scripts to be executed in
  // browsers that do not yet support the CSP Version 3 value `strict-dynamic`,
  // namely Safari and Firefox for Android.
  const customHtml = cookieConsent ? addNonceToScriptTags(data.get('html')) : stripScriptTags(data.get('html'))

  const handlePluginActiveStateChange = (isActive: boolean) => {
    setIsSettingActive(isActive)
  }

  if (editorView) {
    const placeholder = (
      <div className={customHtml ? 'dali-plugin-freehtml-placeholder' : 'dali-grid-element-placeholder'}>
        <span className="dali-plugin-freehtml-placeholder-icon" />
        {customHtml ? (
          <p>
            <strong>{t('components.freeHtmlElementComponent.placeholder.text')}</strong>
            <br />
            {t('components.freeHtmlElementComponent.placeholder.hint')}
          </p>
        ) : (
          <button className="dali-plugin-freehtml-placeholder-button-add" onClick={() => onEdit()}>
            {t('components.freeHtmlElementComponent.addHtmlButton.label')}
          </button>
        )}
      </div>
    )

    return (
      <div
        className={`dali-plugin-freehtml ${isSettingActive ? 'dali-grid-element-highlighted' : ''}`}
        ref={rootElement}
      >
        <div suppressHydrationWarning={true} dangerouslySetInnerHTML={{ __html: customHtml }} />
        {placeholder}
        {editorMode === 'edit' && (
          <SettingsLayer
            className="dali-settingslayer-freehtml"
            referenceElement={rootElement.current}
            placement="top"
            onActiveStateChange={handlePluginActiveStateChange}
            onEscapeKeyDown={onCancel}
          >
            {({ renderLayout }) => <FreeHtmlSettings {...{ data, onDataChange, onSave, onCancel, renderLayout }} />}
          </SettingsLayer>
        )}
      </div>
    )
  }

  return customHtml ? <InnerHTML className="dali-plugin-freehtml" html={customHtml} /> : null
}

FreeHtmlPlugin.actionBarButtons = {
  save: false,
  edit: true,
}

const SCRIPT_TAGS_RE = /<script\s?([\s\S]*?)>([\s\S]*?)<\/script>/gi

function stripScriptTags(htmlSnippet: string): string {
  return htmlSnippet.replace(SCRIPT_TAGS_RE, '')
}

export function addNonceToScriptTags(htmlSnippet: string): string {
  if (typeof window === 'undefined') return htmlSnippet
  const nonce = document.querySelector<HTMLElement>('script[nonce]')?.nonce

  return htmlSnippet.replace(
    SCRIPT_TAGS_RE,
    (_match, attributes, body) => `<script ${attributes} nonce="${nonce}">${body}</script>`,
  )
}
