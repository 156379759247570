import type { ReactNode, RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import cc from 'classcat'

export const useIsAboveView = (): [RefObject<HTMLDivElement | null>, boolean] => {
  const ref = useRef<HTMLDivElement>(null)
  const [isAboveView, setIsAboveView] = useState(false)

  useEffect(() => {
    const markerElement = ref.current

    // Observe marker element to detect whether it has been scrolled out of view
    // via the upper edge of the viewport.
    const observer = new IntersectionObserver((entries) => setIsAboveView(entries[0]?.boundingClientRect.top < 0))
    if (markerElement) observer.observe(markerElement)

    return () => {
      if (markerElement) observer?.unobserve(markerElement)
    }
  })

  return [ref, isAboveView]
}

type Props = Readonly<{
  onScrollIntoView: (inView: boolean) => void
  children?: ReactNode
  className?: string
}>
export default function ToolbarTop({ onScrollIntoView, children, className }: Props) {
  const [refTopbar, isAboveView] = useIsAboveView()

  useEffect(() => {
    onScrollIntoView(!isAboveView)
  }, [isAboveView]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div ref={refTopbar} />
      {children && (
        <div
          className={cc({
            [`${className}-sticky`]: isAboveView,
          })}
        >
          <div className={className}>{children}</div>
        </div>
      )}
    </>
  )
}
