import SwiperCore, { A11y, Autoplay, Controller, EffectFade, Keyboard, Navigation, Thumbs } from 'swiper/core'
import { Component, createRef } from 'react'
import { array, bool, func, number, object, oneOfType, shape, string } from 'prop-types'
import { merge } from 'lodash-es'

// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([A11y, Autoplay, Controller, EffectFade, Keyboard, Navigation, Thumbs])

export default class Swiper extends Component {
  static propTypes = {
    slides: array.isRequired,
    renderSwiper: func.isRequired,
    autoPlay: oneOfType([bool, shape({ delay: number })]),
    customControlClass: string,
    controller: object,
    onActiveIndexChange: func,
    refSet: object,
    // ...plus everything from http://idangero.us/swiper/api
  }

  get options() {
    // Allow the theme designer to change the effect via CSS: .swiper-slide {animation: fade 1s;}
    const { animationName, animationDuration } = this.domNode.current
      ? window.getComputedStyle(this.domNode.current.querySelector('.swiper-slide'))
      : {}
    const customSpeed = animationDuration || '0s'
    const speed = parseFloat(customSpeed) * (customSpeed.endsWith('ms') ? 1 : 1000) || 300

    return {
      // see http://idangero.us/swiper/api
      slidesPerView: 1,
      centeredSlides: true,
      direction: 'horizontal',
      navigation: {
        disabledClass: 'swiper-button-disabled',
        nextEl: this.refs.nextButton || (this.props.refSet && this.props.refSet.nextButtonRef.current),
        prevEl: this.refs.prevButton || (this.props.refSet && this.props.refSet.prevButtonRef.current),
      },
      effect: animationName || 'slide',
      speed,
      threshold: 3,
      ...this.props,
    }
  }

  domNode = createRef()

  state = {
    activeIndex: 0,
    ready: false,
  }

  componentDidMount() {
    this.lazyLoadObserver = new MutationObserver(() => {
      this.needsLoopReInit = true
    })

    const swiperContainer =
      this.refs.swiperContainer || (this.props.refSet && this.props.refSet.swiperContainerRef.current)

    swiperContainer.querySelectorAll('img, iframe').forEach((node) => {
      this.lazyLoadObserver.observe(node, {
        attributes: true,
        attributeFilter: ['class'],
      })
    })

    this.gallerySwiper = new SwiperCore(swiperContainer, {
      ...this.options,
      on: {
        ...this.options.on,
        resize: () => {
          this.setSlideWidthCssProp()
        },
        slideChangeTransitionEnd: (...args) => {
          if ((this.options.on || {}).slideChangeTransitionEnd) this.options.on.slideChangeTransitionEnd(...args)
          this.updateActiveIndex()
        },
        // slideChangeTransitionEnd doesn't fire consistently (https://github.com/nolimits4web/Swiper/issues/1988)
        slideChange: (...args) => {
          this.slideChangeTimeoutId = setTimeout(() => {
            if ((this.options.on || {}).slideChange) this.options.on.slideChange(...args)
            this.updateActiveIndex()

            // Lazy loaded content (img/iframe) might not have been loaded upon Swiper loop mode initialization.
            // To avoid blank slides, re-init the loop mode in this case (updating the duplicated Swiper slides).
            if (this.needsLoopReInit) {
              this.needsLoopReInit = false
              this.lazyLoadObserver.disconnect()
              this.reInitLoop()
            }
          }, this.options.speed)
        },
      },
    })
    this.toggleSlideLock()
    // have to wait for a nested slider to be rendered before linking them
    // also check if domNode still exists after timeout
    setTimeout(() => {
      if (this.domNode.current) {
        this.enableController()
        this.setSlideWidthCssProp()
      }
    }, 0)

    this.setState({ ready: true })
  }

  componentWillUnmount() {
    clearTimeout(this.slideChangeTimeoutId)
    this.lazyLoadObserver.disconnect()
    this.gallerySwiper.destroy()
  }

  componentDidUpdate() {
    merge(this.gallerySwiper.params, this.options)
    if (this.options.autoplay) this.gallerySwiper.autoplay.start()
    else this.gallerySwiper.autoplay.stop()
    this.gallerySwiper.update()
    this.toggleSlideLock()
    this.setSlideWidthCssProp()
  }

  updateActiveIndex() {
    if (!this.options.customControlled) {
      const activeIndex = this.state.activeIndex
      this.setState(
        { activeIndex: this.gallerySwiper ? this.gallerySwiper.realIndex : 0 },
        /* provide a callback that actually represents a change in the active index opposed to the semi working ones from the swiper */
        () =>
          this.options.onActiveIndexChange &&
          activeIndex !== this.state.activeIndex &&
          this.options.onActiveIndexChange(this.state.activeIndex),
      )
    }
  }

  setSlideWidthCssProp() {
    const slide = this.gallerySwiper.el.querySelector('.swiper-slide-active')
    if (slide) this.gallerySwiper.el.parentElement.style.setProperty('--slide-width', `${slide.clientWidth}px`)
  }

  // allow the theme designer to connect and sync a nested swiper via a `customControllClass` property
  enableController() {
    this.gallerySwiper.controller = {
      ...this.gallerySwiper.controller,
      ...this.props.controller,
      control: (this.domNode.current.querySelector('.' + this.props.customControlClass) || {}).swiper,
    }
    this.gallerySwiper.update()
  }

  toggleSlideLock() {
    this.gallerySwiper.allowSlidePrev = this.gallerySwiper.allowSlideNext = this.props.slides.length !== 1
    this.gallerySwiper.update()
  }

  reInitLoop() {
    if (this.options.loop) {
      // Duplicated Swiper loop mode slides aren't updated when the content of the original slide changes.
      // It was stated that it should be done manually if needed, see:
      // https://github.com/nolimits4web/swiper/issues/2629#issuecomment-414247581
      //
      // Taken from Swiper v4 source code (v3 had a "reLoop" method as shortcut):
      // https://github.com/nolimits4web/swiper/blob/a7066aa635933a7446eb292989e0e2c43cd05ebe/dist/js/swiper.js#L3347-L3352
      // https://github.com/nolimits4web/swiper/issues/2336
      this.gallerySwiper.loopDestroy()
      this.gallerySwiper.loopCreate()
      this.gallerySwiper.updateSlides()
    }
  }

  // activeIndex and realIndex differ in loop mode. See http://idangero.us/swiper/api
  getCorrectSlideIndex(index) {
    const { activeIndex, realIndex, loopedSlides } = this.gallerySwiper
    const totalNumSlides = this.props.slides.length + loopedSlides
    const offsetIndex = index + activeIndex - realIndex

    return offsetIndex > totalNumSlides ? index + 1 : offsetIndex
  }

  render() {
    const slides = this.props.slides

    return (
      <div
        ref={this.domNode}
        onMouseEnter={() => this.gallerySwiper.autoplay.stop()}
        onMouseLeave={() => (this.options.autoplay ? this.gallerySwiper.autoplay.start() : undefined)}
      >
        {this.props.renderSwiper({
          slides: slides.map((slide, index) => ({ ...slide, index })),
          slideIdx: this.state.activeIndex,
          ready: this.state.ready,
          // wrap in iife since `this.gallerySwiper` is not yet created (created from template `ref`)
          slideNext: (...args) => this.gallerySwiper.slideNext(...args),
          slidePrev: (...args) => this.gallerySwiper.slidePrev(...args),
          slideTo: (index, ...args) => this.gallerySwiper.slideTo(this.getCorrectSlideIndex(index), ...args),
        })}
      </div>
    )
  }
}
