let nextRequestId = 0

const equalsShallow = (a, b) => {
  const ak = Object.keys(a || {})
  const bk = Object.keys(b || {})

  if (ak.length !== bk.length) return false

  for (let i = 0, l = ak.length; i < l; i += 1) {
    if (ak.indexOf(bk[i]) < 0 || a[ak[i]] !== b[ak[i]]) return false
  }

  return true
}

export default function apiCallMiddleware(api) {
  let runningRequests = []

  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      const { type, callApi, shouldCallApi, payload, memorize, idempotent, options } = action

      if (callApi) {
        const state = getState()
        const skip = shouldCallApi && !shouldCallApi(state, payload)
        const memorized = memorize ? memorize(state) : {}

        if (!skip) {
          let serverRequestId
          const requestId = nextRequestId++
          const running =
            idempotent === true &&
            runningRequests.find(
              (req) => req.type === type && req.idempotent === idempotent && equalsShallow(req.payload, payload),
            )

          if (running) {
            return running.request
          }

          dispatch({
            type,
            ...payload,
            requestId,
          })

          const request = Promise.resolve(
            callApi(api, payload, options)
              .then((res) => {
                runningRequests = runningRequests.filter((req) => req.requestId !== requestId)
                return res
              })
              .catch((err) => {
                runningRequests = runningRequests.filter((req) => req.requestId !== requestId)

                // Axios Error
                if (err.response) {
                  const name = 'ReduxApiCallError'
                  const status = Number.isInteger(err.response.status) ? err.response.status : 500

                  serverRequestId = err.response.headers['x-epages-requestid'] || err.response.headers['x-b3-traceid']

                  switch (typeof err.response.data) {
                    case 'string':
                      // read the error message from raw string response
                      return Promise.reject(
                        Object.assign(err, {
                          message: err.response.data,
                          name,
                          status,
                          serverRequestId,
                        }),
                      )
                    case 'object': {
                      const { statusMessage } = err.response.data

                      // Fallback to simple 500 if we don't have an error message
                      if (typeof err.response.data.message !== 'string') break

                      // try the read the error message from JSON response like {message: "...", ...}
                      return Promise.reject(
                        Object.assign(err, {
                          message: err.response.data.message,
                          name,
                          status,
                          statusMessage,
                          serverRequestId,
                        }),
                      )
                    }
                    default:
                      // unknown error message format
                      return Promise.reject(
                        Object.assign(new Error('Internal Server Error'), {
                          status: 500,
                        }),
                      )
                  }
                  // Non Axios error, but still an error
                } else if (err instanceof Error) {
                  const status = Number.isInteger(err.status) ? err.status : 500

                  return Promise.reject(Object.assign(err, { status }))
                }

                // neither an Error instance nor an axios error response
                return Promise.reject(
                  Object.assign(new Error('Internal Server Error'), {
                    status: 500,
                  }),
                )
              }),
          )
          runningRequests.push({ requestId, type, idempotent, payload, request })

          return request
            .then((response) => {
              dispatch({
                type: type + '_SUCCESS',
                ...payload,
                requestId,
                response,
                options,
              })
              return response
            })
            .catch((error) => {
              dispatch({
                type: type + '_FAILURE',
                ...payload,
                requestId,
                serverRequestId,
                memorized,
                errorMessage: error.message,
                statusMessage: error.statusMessage,
                options,
              })
              return Promise.reject(error)
            })
        }

        return Promise.resolve(undefined)
      }

      return next(action)
    }
}
