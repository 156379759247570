import type { Ref } from 'react'
import { useRef } from 'react'

import Swiper from '../../templateComponents/Workspace/plugins/contentslider/Swiper'

type SwiperComponentProps = Readonly<{
  slides: Core.HeaderUsp[]
  refSet: RefSet
}>

type RefSet = {
  swiperContainerRef: Ref<HTMLDivElement>
  nextButtonRef: Ref<HTMLDivElement>
  prevButtonRef: Ref<HTMLDivElement>
}

function SwiperComponent({ slides, refSet }: SwiperComponentProps) {
  return (
    <>
      <div className="swiper-container" ref={refSet.swiperContainerRef} key="container">
        <ul className="swiper-wrapper header-usp-list">
          {slides.map((usp, index) => (
            <li className="swiper-slide header-usp-list-item" key={usp.text + index}>
              <span className="header-usp-list-item-wrapper">
                {usp.link ? (
                  <a
                    href={usp.link}
                    target={usp.openInNewTab ? '_blank' : undefined}
                    rel={usp.openInNewTab ? 'noreferrer noopener' : undefined}
                  >
                    {usp.icon.show && <span className={`ep-${usp.icon.name}-icon`} />}
                    <span className="header-usp-list-item-text">{usp.text}</span>
                  </a>
                ) : (
                  <>
                    {usp.icon.show && <span className={`ep-${usp.icon.name}-icon`} />}
                    <span className="header-usp-list-item-text">{usp.text}</span>
                  </>
                )}
                <span></span>
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="swiper-arrows" key="swiper-arrows">
        <div ref={refSet.nextButtonRef} className="swiper-arrow-right" />
        <div ref={refSet.prevButtonRef} className="swiper-arrow-left swiper-button-disabled" />
      </div>
    </>
  )
}

type HeaderUspProps = Readonly<{ headerUsps: Core.HeaderUsp[] }>

type RenderSwiperProps = { slides: Core.HeaderUsp[] }

export default function HeaderUsp({ headerUsps = [] }: Readonly<HeaderUspProps>) {
  const refSet = {
    swiperContainerRef: useRef<HTMLDivElement>(null),
    nextButtonRef: useRef<HTMLDivElement>(null),
    prevButtonRef: useRef<HTMLDivElement>(null),
  }

  if (!headerUsps.length) return null

  return (
    <>
      <div className="header-usp mobile-view">
        <Swiper
          slides={headerUsps}
          loop={true}
          autoplay={{ delay: 5000 }}
          renderSwiper={({ slides }: Readonly<RenderSwiperProps>) => (
            <SwiperComponent refSet={refSet} slides={slides} />
          )}
          refSet={refSet}
        />
      </div>
      <div className="header-usp desktop-view">
        <ul className="header-usp-list">
          {headerUsps.map((usp, index) => (
            <li className="header-usp-list-item" key={`usp-${index}`}>
              <span className="header-usp-list-item-wrapper">
                {usp.link ? (
                  <a
                    href={usp.link}
                    target={usp.openInNewTab ? '_blank' : undefined}
                    rel={usp.openInNewTab ? 'noreferrer noopener' : undefined}
                  >
                    {usp.icon.show && <span className={`ep-${usp.icon.name}-icon`} />}
                    <span className="header-usp-list-item-text">{usp.text}</span>
                  </a>
                ) : (
                  <>
                    {usp.icon.show && <span className={`ep-${usp.icon.name}-icon`} />}
                    <span className="header-usp-list-item-text">{usp.text}</span>
                  </>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
