import 'scroll-shadow-element'
import { browserHistory as history } from 'react-router'
import { hydrateRoot } from 'react-dom/client'
import { loadableReady } from '@loadable/component'
import Immutable from 'immutable'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import * as storeActions from '../web/store/actions'
import { createStore } from '../web/store'
import {
  enableOptOut as enableGAOptOut,
  initializeGoogleAnalyticsEc,
  setGAUsedState,
} from '../web/utils/googleAnalytics'
import { ensureResourceBundlesLoaded } from '../web/i18next'
import { init as initTracking } from '../web/utils/tracking'
import { initializeDataLayer } from '../web/utils/dataLayer'
import { refreshCustomerLogin } from '../web/store/actions'
import App from '../web/App'

const { shopSlug, additionalScripts } = window.__EP

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000, {
    // https://github.com/dequelabs/axe-core/blob/develop/doc/API.md#axe-core-tags
    runOnly: ['wcag2a', 'wcag2aa', 'wcag21a', 'wcag21aa'],
  })
}

// will be updated on postMessage from the MBO
const storeInitialState = Immutable.fromJS(JSON.parse(window.__EP.storeInitialState)).setIn(['view', 'mboMenu'], [])

if ('serviceWorker' in navigator) {
  const serviceWorkerScripts = storeInitialState
    .get('scriptTags', Immutable.List())
    .filter((tag) => tag.get('type') === 'SERVICEWORKER')

  const encodedScripts = serviceWorkerScripts
    .map((script) => 'url[]=' + encodeURIComponent(script.get('url')))
    .join('&')

  ;(async () => {
    const scriptURL = '/sw.js?' + encodedScripts

    // unregister ServiceWorker whenever scriptURL has changed ('SERVICEWORKER' script tag added or removed)
    const { serviceWorker } = navigator
    if (serviceWorker.controller && !serviceWorker.controller.scriptURL.endsWith(scriptURL))
      await serviceWorker.getRegistration().then((reg) => reg.unregister())

    if (serviceWorkerScripts.size > 0) await serviceWorker.register(scriptURL)
  })()
}

initTracking()
initAnalytics()

// get the token query-parameter
const queryToken = (window.location.search.match(/token=([^&]+)/) || [])[1]
const queryPreviewTheme = (window.location.search.match(/previewTheme=([^&]+)/) || [])[1]
const queryPreviewThemeSettings = (window.location.search.match(/previewThemeSettings=([^&]+)/) || [])[1]
// create an axios instance for all api-actions
const api = axios.create({
  params: {
    shop: shopSlug,
    ...(queryPreviewTheme ? { previewTheme: queryPreviewTheme } : {}),
    ...(queryPreviewThemeSettings ? { previewThemeSettings: queryPreviewThemeSettings } : {}),
  },
  headers: {
    common: {
      // set authorization-header for all api-actions if a token is given as query-parameter
      ...(queryToken ? { Authorization: 'Bearer ' + queryToken } : {}),
    },
  },
})
const store = createStore(api, storeInitialState)

if (process.env.NODE_ENV !== 'production') {
  Object.assign(window.__EP, {
    store,
    storeActions,
  })
}

const refreshCustomerAuthInterval = window.setInterval(refreshCustomerAuth, 10000)
function refreshCustomerAuth() {
  if (store.getState().getIn(['customer', 'loggedIn'])) {
    const ttl = store.getState().getIn(['customer', 'loggedInUntil'], Infinity) - Date.now()
    // refresh token when TTL is below 15 minutes
    if (ttl < 15 * 60 * 1000) {
      store.dispatch(refreshCustomerLogin()).catch(() => {
        window.clearInterval(refreshCustomerAuthInterval)
      })
    }
  }
}

window.addEventListener(
  'message',
  function (event) {
    if (event.data.type) {
      switch (event.data.type) {
        case 'MBO_MENU':
          store.dispatch(storeActions.setMboMenu(event.data.payload))
          break
        // Alter default authorization-header of the axios instance
        case 'TOKEN':
          api.defaults.headers.common.Authorization = 'Bearer ' + event.data.payload
          break
        case 'MBO_BASE_URL':
          store.dispatch(storeActions.setMboBaseUrl(event.data.payload))
          break
        default:
          break
      }
    } else {
      if (event.data.mboMenu) {
        store.dispatch(storeActions.setMboMenu(event.data.mboMenu))
      }

      if (event.data.token) {
        // Alter default authorization-header of the axios instance
        api.defaults.headers.common.Authorization = 'Bearer ' + event.data.token
      }
    }
  },
  false,
)

history.listen((location) => store.dispatch(storeActions.updateLocation(location)))

loadI18nextResourceBundles().then(() => {
  loadableReady(() => {
    hydrateRoot(
      document,
      React.createElement(App, {
        store,
        history,
        storeInitialState,
        additionalScripts,
      }),
    )
  })
})

async function loadI18nextResourceBundles() {
  const shopLocale = storeInitialState.getIn(['shop', 'locale']).replace('_', '-')
  const interfaceLanguage = storeInitialState.getIn(['view', 'interfaceLanguage'])

  // always have the fallback language prepared
  await ensureResourceBundlesLoaded('en')
  await ensureResourceBundlesLoaded(shopLocale)

  if (interfaceLanguage) await ensureResourceBundlesLoaded(interfaceLanguage)
}

function initAnalytics() {
  const isEditor = storeInitialState.getIn(['view', 'editorMode'])

  // `window.ga` (set by the Google Analytics script tag in the HTML <head>)
  // indicates whether Google Analytics can be used or not.
  //
  // This also works in case of user opt-out (when the GA opt-out cookie exists),
  // because contrary to the recommended implementation by Google, we just don't
  // load the analytics script at all in this case.
  // See: https://hackademix.net/2010/05/26/google-analytics-opt-out-snake-oil/
  const hasGoogleAnalyticsOnPage = Boolean(window.ga)

  enableGAOptOut(storeInitialState.getIn(['shop', 'userSettings', 'googleAnalytics', 'trackingId']))
  setGAUsedState(hasGoogleAnalyticsOnPage && isEditor && window.top === window.self)
  initializeGoogleAnalyticsEc()
  initializeDataLayer()
}
