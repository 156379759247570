import { Component } from 'react'
import Immutable from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import cc from 'classcat'

import compose from '../../../utils/compose'
import equalsShallow from './utils/equals-shallow'
import translate from '../../../utils/translate'
import withI18n from '../../../components/withI18n'

class Element extends Component {
  static propTypes = {
    config: PropTypes.shape({
      plugins: PropTypes.object.isRequired,
    }).isRequired,
    editorView: PropTypes.bool.isRequired,
    element: ImmutablePropTypes.map.isRequired,
    isMultiColumn: PropTypes.bool,
    onElementChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.initialData = props.element.get('data')
    this.isImagePlugin = props.element.get('type') === 'epages.image'
  }

  state = {
    editorMode: 'view',
    editAction: null,
    deleteRequested: false,
    element: undefined,
  }

  get element() {
    return this.state.element || this.props.element
  }

  get isClean() {
    return !this.state.element || Immutable.is(this.state.element, this.props.element)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !equalsShallow(nextProps, this.props) || !equalsShallow(nextState, this.state)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.element.get('data') !== this.props.element.get('data')) {
      // discard current state and hence use element from props again, see `get element()`
      this.setState({ element: undefined })
    }
  }

  emitChange = (newElement) => {
    const { element, onElementChange } = this.props

    if (!Immutable.is(newElement, element)) {
      onElementChange(newElement)
    }
  }

  handleEditButtonClick = () => {
    if (this.slateEditor) this.slateEditor.focus()
    this.setState({ editorMode: 'edit' })
  }

  handlePluginDataChange = (newPluginData) => {
    this.setState({ element: this.element.set('data', newPluginData) })
  }

  handleImageCropEditButtonClick = () => {
    this.setState({ editorMode: 'edit', editAction: 'editImage' })
  }

  handlePluginEdit = (editAction = null) => {
    this.setState({ editorMode: 'edit', editAction })
  }

  handlePluginCancel = () => {
    this.setState({ editorMode: 'view', editAction: null })
    this.handlePluginDataChange(this.initialData)
  }

  handlePluginSave = (newPluginData, keepOpen = false) => {
    this.initialData = newPluginData
    if (!keepOpen) this.setState({ editorMode: 'view', editAction: null })
    this.emitChange(this.element.set('data', newPluginData))
  }

  setRef = (element) => {
    // only used with TextPlugin
    this.slateEditor = element
  }

  render() {
    const { config, element, editorView, t } = this.props
    const { editorMode } = this.state
    const plugin = config.plugins[element.get('type')]

    if (plugin) {
      const PluginComponent = plugin.component

      // TODO: Change so that the Plugin gets props that tell it what to render as action bar buttons
      // instead of having it asked for action buttons it likes to offer from the component that actually renders it
      const { edit } = PluginComponent.actionBarButtons || { edit: true }
      const showEditButtons = edit && editorView && editorMode === 'view'

      return (
        <div className={cc(['dali-grid-element', { 'dali-grid-element-editview': editorView }])}>
          {showEditButtons && (
            <div className="dali-grid-element-buttons">
              {this.isImagePlugin && (
                <button
                  type="button"
                  className="dali-grid-element-edit-button dali-grid-element-edit-button-crop"
                  title={t('components.editorWorkspaceComponent.imageCropEditElementButton.label')}
                  disabled={!element.getIn(['data', 'src'])}
                  onClick={this.handleImageCropEditButtonClick}
                />
              )}
              <button
                type="button"
                className="dali-grid-element-edit-button dali-grid-element-edit-button-edit"
                title={t(
                  this.isImagePlugin
                    ? 'components.editorWorkspaceComponent.imageElementEditButton.label'
                    : 'components.editorWorkspaceComponent.editElementButton.label',
                )}
                disabled={this.isImagePlugin && !element.getIn(['data', 'src'])}
                onClick={this.handleEditButtonClick}
              />
            </div>
          )}

          <div className="dali-grid-element-wrapper">
            <PluginComponent
              config={plugin.config}
              editorView={this.props.editorView}
              editorMode={this.state.editorMode}
              editAction={this.state.editAction}
              isMultiColumn={this.props.isMultiColumn}
              id={this.element.get('_id')}
              type={this.element.get('type')}
              data={this.element.get('data')}
              onDataChange={this.handlePluginDataChange}
              onSave={this.handlePluginSave}
              onEdit={this.handlePluginEdit}
              onCancel={this.handlePluginCancel}
              setRef={this.setRef}
            />
          </div>
        </div>
      )
    } else {
      return <div>{'Unknown plugin type ' + element.get('type')}</div>
    }
  }
}

export default compose(withI18n('interface'), translate())(Element)
