import type { ReactElement } from 'react'
import { useRef, useState } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

import { handleEscapeKeyDown } from '../../../../../utils/hooks/useSortableHelpers'
import { useTranslate } from '../../../../../utils/translate'
import Accordion from '../components/Accordion/Accordion'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './ExpanderSettings'))

export default function ExpanderPlugin({
  data = Immutable.fromJS({ entries: [] }),
  editorMode,
  editorView,
  onEdit,
  onCancel,
  onDataChange,
  onSave,
}: Readonly<WorkspacePluginProps>): ReactElement | null {
  const t = useTranslate('interface', 'components.expanderElementComponent')
  const [isSettingActive, setIsSettingActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const pluginActiveClasses = cc([
    'dali-plugin-expander',
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        className="dali-settingslayer-expander"
        referenceElement={ref.current}
        placement="left" // on the right, the reordering drop animation is sloppy
        onActiveStateChange={(isSettingActive: boolean) => setIsSettingActive(isSettingActive)}
        onEscapeKeyDown={handleEscapeKeyDown(onCancel)}
      >
        {({ renderLayout }) => <SettingsForm {...{ data, onDataChange, onSave, onCancel, renderLayout }} />}
      </SettingsLayer>
    )
  }

  const accordionData = data.get('entries').map((entry) => ({
    title: entry.get('title'),
    content: entry.get('detailsHtml'),
  }))

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {data.get('entries') && data.get('entries').some((entry) => entry.get('title') || entry.get('detailsHtml')) ? (
          data.get('entries').map((entry, index) => (
            <details key={index}>
              <summary>{entry.get('title')}</summary>
              <div dangerouslySetInnerHTML={{ __html: entry.get('detailsHtml') }}></div>
            </details>
          ))
        ) : (
          <div className={'dali-grid-element-placeholder'}>
            <span className="dali-plugin-expander-placeholder-icon" />
            <button className="dali-plugin-expander-placeholder-button-add" onClick={() => onEdit()}>
              {t('addTopicsButton.label')}
            </button>
          </div>
        )}
        {editorMode === 'edit' ? renderSettingsLayer() : null}
      </div>
    )
  }

  return (
    <div className={pluginActiveClasses}>
      <Accordion data={accordionData} innerTextClassName={'dali-plugin-expander-text'} />
    </div>
  )
}

ExpanderPlugin.actionBarButtons = { edit: true }
