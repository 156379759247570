/**
 * add a script tag after the last script tag on the page
 *
 * @param {String}  scriptUrl    url for the script
 * @param {Boolean} [asyncScript]  if the script is loaded async or not
 * @param {Function} [onload]      function to execute after the script has loaded
 */
export default function addScript(scriptUrl, asyncScript = true, onload) {
  const script = 'script'
  const scriptElement = document.createElement(script)
  const target = document.getElementsByTagName(script)[0]

  scriptElement.onload = () => {
    scriptElement.parentNode?.removeChild(scriptElement)
    onload?.()
  }
  scriptElement.async = asyncScript
  scriptElement.src = scriptUrl

  target.parentNode.insertBefore(scriptElement, null)
}
