import type { ButtonHTMLAttributes, ReactElement } from 'react'
import cc from 'classcat'

import { useTranslate } from '../utils/translate'
import focusDocumentBody from '../utils/focusDocumentBody'

type ScrollToTopButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

/**
 * Scrolls to the top of the document and focuses the body element.
 */
export default function ScrollToTopButton({ className, ...htmlProps }: Readonly<ScrollToTopButtonProps>): ReactElement {
  const t = useTranslate('shop', 'components.backToTopButton')

  return (
    <button {...htmlProps} className={cc(['scroll-to-top-button', className])} onClick={handleButtonClick}>
      {/* Unsetting transform so the button is positioned correctly in the Beyond preview. */}
      <style>{`.body { transform: unset; }`}</style>
      <span className="scroll-to-top-button-text">{t('label')}</span>
      <i className="icon" aria-hidden="true" />
    </button>
  )
}

function handleButtonClick() {
  window.scrollTo({ top: 0, behavior: 'smooth' })

  // Focus the body element to reset the tab order.
  focusDocumentBody()
}
