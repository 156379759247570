import type { ReactElement } from 'react'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'

import type { BackgroundColorSettings } from './Block'
import { defaultBackgroundColorSettings } from './Block'
import { themeGroupDelimiter } from '../../../../utils/themes'
import { useColorOptions } from '../../../utils/hooks/colorSettings'
import Button from '../../formComponents/Button'
import ButtonRow from '../../formComponents/ButtonRow'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ './SettingsLayer'))
const ColorField = loadable(() => import(/* webpackChunkName: "editor" */ '../../formComponents/ColorField'), {
  resolveComponent: (components) => components.ColorFieldRaw,
})

type Props = {
  currentSettings: BackgroundColorSettings
  onSettingsChange: (settings: BackgroundColorSettings) => void
  onCancel: () => void
  onSave: () => void
} & TranslateProps

export default function BlockColorSettings({
  t,
  currentSettings,
  onSave,
  onSettingsChange,
  onCancel,
}: Readonly<Props>): ReactElement {
  const [showBlockColorSettings, setShowBlockColorSettings] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const {
    backgroundColor,
    colorOrGradient,
    gradientEndColor,
    gradientMidColor,
    gradientOrientation,
    gradientStartColor,
    showGradientMidColor,
  } = currentSettings

  const saveColor = () => {
    setShowBlockColorSettings(false)
    onSave()
  }

  const cancelHandler = () => {
    onCancel()
    setShowBlockColorSettings(false)
  }

  const handleSwitch = (event) => {
    event.preventDefault()
    onSettingsChange({
      ...currentSettings,
      gradientStartColor: gradientEndColor,
      gradientEndColor: gradientStartColor,
    })
  }

  const toggleColorSettings = () => {
    if (showBlockColorSettings) {
      cancelHandler()
    } else setShowBlockColorSettings(true)
  }

  const handleReset = () => {
    onSettingsChange(defaultBackgroundColorSettings)
  }

  const themeName = useSelector<State, string>((state) => state.getIn(['shop', 'theme', 'name'], '').replace('.', '_'))
  const [themeGroupName] = themeName.split(themeGroupDelimiter)
  const tThemeSettings = (subKey: string) => t(`themes:${themeGroupName}.settings.${subKey}`)
  const tColorOptions = (subKey: string) => t(`components.colorFieldComponent.popover.colorPresets.${subKey}.tooltip`)
  const colorOptions = useColorOptions(tColorOptions, tThemeSettings)

  const title = t('backgroundButton.settingsLayer.title')

  const body = (
    <div className="dali-settingslayer-main-content">
      <div className="dali-settingslayer-element">
        <label htmlFor="color">
          <input
            type="radio"
            id="color"
            checked={colorOrGradient === 'color'}
            onChange={() => onSettingsChange({ ...currentSettings, colorOrGradient: 'color' })}
          />
          {t('backgroundButton.settingsLayer.backgroundStyleRadioButtons.solid.label')}
        </label>
        <div className="dali-settingslayer-solid-background-row" aria-disabled={colorOrGradient === 'gradient'}>
          <ColorField
            value={backgroundColor}
            className="ep-color-field-box"
            presetColors={colorOptions.toJS()}
            offsetDistance={30}
            resetValue={defaultBackgroundColorSettings.backgroundColor}
            onChange={(backgroundColor = defaultBackgroundColorSettings.backgroundColor) =>
              onSettingsChange({ ...currentSettings, backgroundColor })
            }
          />
          <span>{t('backgroundButton.settingsLayer.backgroundStyleRadioButtons.solid.colorPicker.label')}</span>
        </div>
      </div>

      <div className="dali-settingslayer-element dali-settingslayer-gradient-background">
        <label htmlFor="gradient" className="dali-settingslayer-gradient-background-label">
          <input
            type="radio"
            id="gradient"
            checked={colorOrGradient === 'gradient'}
            onChange={() => onSettingsChange({ ...currentSettings, colorOrGradient: 'gradient' })}
          />
          {t('backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.label')}
        </label>
        <fieldset className="dali-settingslayer-gradient-background-row" disabled={colorOrGradient === 'color'}>
          <legend>{t('backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.settings')}</legend>
          <div className="dali-settingslayer-gradient-background-content">
            <fieldset className="dali-settingslayer-gradient-background-number">
              <legend className="sr-only">
                {t(
                  'backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.numberRadioButtons.accessibilityLabel',
                )}
              </legend>
              <span>
                <div className="dali-settingslayer-gradient-background-number-radio-button">
                  <input
                    type="radio"
                    id="twoColors"
                    checked={!showGradientMidColor}
                    onChange={() => onSettingsChange({ ...currentSettings, showGradientMidColor: false })}
                  />
                  <label htmlFor="twoColors">
                    {t(
                      'backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.numberRadioButtons.twoColors',
                    )}
                  </label>
                </div>
                <div className="dali-settingslayer-gradient-background-number-radio-button">
                  <input
                    type="radio"
                    id="threeColors"
                    checked={showGradientMidColor}
                    onChange={() => onSettingsChange({ ...currentSettings, showGradientMidColor: true })}
                  />
                  <label htmlFor="threeColors">
                    {t(
                      'backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.numberRadioButtons.threeColors',
                    )}
                  </label>
                </div>
              </span>
            </fieldset>
            <button className="dali-settingslayer-gradient-background-switch" onClick={handleSwitch}>
              {t('backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.switchColorsButton.label')}
            </button>
            <div
              className="dali-settingslayer-gradient-background-content-bar"
              style={{
                background: showGradientMidColor
                  ? `linear-gradient( 90deg, ${gradientStartColor}, ${gradientMidColor}, ${gradientEndColor})`
                  : `linear-gradient( 90deg, ${gradientStartColor}, ${gradientEndColor})`,
              }}
            ></div>
            <div
              className={`dali-settingslayer-gradient-background-content-colors ${
                showGradientMidColor ? 'dali-settingslayer-gradient-background-content-colors-three' : ''
              }`}
            >
              <ColorField
                value={gradientStartColor}
                className="ep-color-field-box"
                presetColors={colorOptions.toJS()}
                offsetDistance={30}
                resetValue={defaultBackgroundColorSettings.gradientStartColor}
                onChange={(gradientStartColor = defaultBackgroundColorSettings.gradientStartColor) =>
                  onSettingsChange({ ...currentSettings, gradientStartColor })
                }
              />
              {showGradientMidColor && (
                <ColorField
                  value={gradientMidColor}
                  className="ep-color-field-box"
                  presetColors={colorOptions.toJS()}
                  offsetDistance={30}
                  resetValue={defaultBackgroundColorSettings.gradientMidColor}
                  onChange={(gradientMidColor = defaultBackgroundColorSettings.gradientMidColor) =>
                    onSettingsChange({ ...currentSettings, gradientMidColor })
                  }
                  placement="right-end"
                />
              )}
              <ColorField
                value={gradientEndColor}
                className="ep-color-field-box"
                presetColors={colorOptions.toJS()}
                offsetDistance={30}
                resetValue={defaultBackgroundColorSettings.gradientEndColor}
                onChange={(gradientEndColor = defaultBackgroundColorSettings.gradientEndColor) =>
                  onSettingsChange({ ...currentSettings, gradientEndColor })
                }
                placement="right-end"
              />
            </div>
          </div>
          <fieldset className="dali-settingslayer-gradient-background-orientation">
            <legend className="dali-settingslayer-gradient-background-orientation-legend ep-form-row-label">
              {t('backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.orientationRadioButtons.label')}
            </legend>
            <div className="dali-settingslayer-gradient-background-orientation-options">
              <span>
                <input
                  type="radio"
                  id="horizontal"
                  checked={gradientOrientation === '90deg'}
                  onChange={() => onSettingsChange({ ...currentSettings, gradientOrientation: '90deg' })}
                />
                <label htmlFor="horizontal">
                  {t(
                    'backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.orientationRadioButtons.horizontal',
                  )}
                </label>
              </span>
              <span>
                <input
                  type="radio"
                  id="vertical"
                  checked={gradientOrientation === '180deg'}
                  onChange={() => onSettingsChange({ ...currentSettings, gradientOrientation: '180deg' })}
                />
                <label htmlFor="vertical">
                  {t(
                    'backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.orientationRadioButtons.vertical',
                  )}
                </label>
              </span>
              <span>
                <input
                  type="radio"
                  id="diagonal"
                  checked={gradientOrientation === '45deg'}
                  onChange={() => onSettingsChange({ ...currentSettings, gradientOrientation: '45deg' })}
                />
                <label htmlFor="diagonal">
                  {t(
                    'backgroundButton.settingsLayer.backgroundStyleRadioButtons.gradient.orientationRadioButtons.diagonal',
                  )}
                </label>
              </span>
            </div>
          </fieldset>
        </fieldset>
        <button type="button" className="ep-settingslayer-block-background-reset-button" onClick={handleReset}>
          {t('backgroundButton.settingsLayer.resetButton.label')}
        </button>
      </div>
    </div>
  )
  const footer = (
    <ButtonRow>
      <Button type="button" variant="cancel" onClick={cancelHandler}>
        {t('backgroundButton.settingsLayer.cancelButton.label')}
      </Button>
      <Button type="button" onClick={saveColor}>
        {t('backgroundButton.settingsLayer.saveButton.label')}
      </Button>
    </ButtonRow>
  )

  return (
    <>
      <div
        ref={buttonRef}
        className="dali-block-actionbar-button dali-block-actionbar-button-background-color"
        title={t('backgroundButton.label')}
        onClick={() => toggleColorSettings()}
      />
      {showBlockColorSettings && (
        <SettingsLayer
          className="ep-settingslayer-block-background"
          referenceElement={buttonRef.current}
          placement="right"
          onEscapeKeyDown={cancelHandler}
        >
          {({ renderLayout }) => renderLayout({ title, body, footer })}
        </SettingsLayer>
      )}
    </>
  )
}
