import type { ReactElement } from 'react'
import { noop } from 'lodash-es'

import FacetedSearchSlideLayer from './FacetedSearchSlideLayer'
import SearchFilterFacet from './SearchFilterFacet'
import SearchFilterRangeFacet from './SearchFilterRangeFacet'
import SearchFilterSelectionFacet from './SearchFilterSelectionFacet'

const facetNameTranslationKeys = {
  Category: 'components.facetedSearch.facet.category',
  ListPrice: 'components.facetedSearch.facet.price',
  Manufacturer: 'components.facetedSearch.facet.brand',
  IsNew: 'components.facetedSearch.facet.productLabel',
}

type Props = {
  facets?: Core.Facets
  totalNumberOfProducts: number
  isOpen: boolean
  isBusy: boolean
  onSelectionFacetChange: (facet: Core.SelectionFacet, value: Core.FacetSelectionValue) => void
  onRangeFacetChange: (
    facet: Core.RangeFacet,
    range: {
      min: number
      max: number
    },
  ) => void
  onResetAllFilters: () => void
  onRequestClose: () => void
} & TranslateProps

export default function SearchFilterOverlay({
  facets,
  totalNumberOfProducts,
  isOpen,
  isBusy,
  onSelectionFacetChange = noop,
  onRangeFacetChange = noop,
  onResetAllFilters = noop,
  onRequestClose = noop,
  t,
}: Readonly<Props>): ReactElement {
  const showResetAllFiltersButton = facets
    ? Object.values(facets).some((facet) => {
        if (facet.type === 'selection') {
          return Object.values(facet.values).some((value) => value.selected)
        } else if (facet.type === 'range') {
          return facet.values?.selected
        } else return false
      })
    : false

  return (
    <FacetedSearchSlideLayer
      hasFacets={!!facets}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t('components.facetedSearch.filterOverlay.label')}
    >
      <div className="filter-overlay-content">
        {isBusy && <div className="busy-layer" />}
        <scroll-shadow>
          <div className="filter-overlay-content-facets">
            {Object.entries(facets || {}).map(([id, facet]) => (
              <SearchFilterFacet
                key={id}
                facetName={t(facetNameTranslationKeys[facet.name] || facet.name)}
                facetType={facet.type}
              >
                {facet.type === 'selection' && (
                  <SearchFilterSelectionFacet facet={facet} onFacetChange={onSelectionFacetChange} />
                )}
                {facet.type === 'range' && <SearchFilterRangeFacet facet={facet} onFacetChange={onRangeFacetChange} />}
              </SearchFilterFacet>
            ))}
          </div>
        </scroll-shadow>
        <div className="filter-overlay-content-footer">
          {showResetAllFiltersButton && (
            <button className="reset-filters" onClick={onResetAllFilters}>
              {t('components.facetedSearch.filters.resetFilters')}
            </button>
          )}
          <button className="filter-overlay-content-footer-button" onClick={onRequestClose}>
            {t('components.facetedSearch.filterOverlay.searchButton', {
              numberOfResults: totalNumberOfProducts,
            })}
          </button>
        </div>
      </div>
    </FacetedSearchSlideLayer>
  )
}
