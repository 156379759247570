// simple wrapper for localStorage with in-memory fallback for Safari private tab
// or disabled cookies (which disabled localStorage as well in modern browsers)
let data = {}

function isLocalStorageSupported() {
  try {
    window.localStorage.setItem('__test__', '1')
    window.localStorage.removeItem('__test__')
    return true
  } catch {
    return false
  }
}

const fallbackStorage: Storage = {
  getItem: (key) => data[key],
  setItem: (key, val) => {
    data[key] = val
  },
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  removeItem: (key) => delete data[key],
  get length() {
    return Object.keys(data).length
  },
  clear() {
    data = {}
  },
  key(index) {
    return Object.keys(data)[index]
  },
}

export default isLocalStorageSupported()
  ? ({
      setItem: (key, val) => {
        try {
          window.localStorage.setItem(key, val)
        } catch {
          // catch QUOTA_EXCEEDED_ERR
        }
      },
      getItem: (key) => window.localStorage.getItem(key),
      removeItem: (key) => window.localStorage.removeItem(key),
      clear: () => window.localStorage.clear(),
      key: (index) => window.localStorage.key(index),
      get length() {
        return window.localStorage.length
      },
    } as Storage)
  : fallbackStorage
