import type { DndContext, UniqueIdentifier } from '@dnd-kit/core'
import { KeyboardSensor, PointerSensor, useDndMonitor, useSensor, useSensors } from '@dnd-kit/core'
import type { MutableRefObject } from 'react'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useImperativeHandle, useRef } from 'react'

export function useSortableA11y(
  t: TranslateProps['t'],
  getTitle: (id: UniqueIdentifier) => string,
): Parameters<typeof DndContext>[0]['accessibility'] {
  const isFirstAnnouncement = useRef(true)
  return {
    announcements: {
      onDragStart: ({ active }) => {
        isFirstAnnouncement.current = true
        return t('sortableList.screenReaderAnnouncements.onDragStart', { source: getTitle(active.id) })
      },
      onDragOver: ({ active, over }) => {
        // https://github.com/clauderic/dnd-kit/blob/e9215e820798459ae036896fce7fd9a6fe855772/stories/2%20-%20Presets/Sortable/Sortable.tsx#L153-L159
        // In this specific use-case, the picked up item's `id` is always the same as the first `over` id.
        // The first `onDragOver` event therefore doesn't need to be announced, because it is called
        // immediately after the `onDragStart` announcement and is redundant.
        if (isFirstAnnouncement.current) {
          if (over?.id && over?.id !== active.id) isFirstAnnouncement.current = false
          else return
        }

        return over
          ? t('sortableList.screenReaderAnnouncements.onDragOver', {
              source: getTitle(active.id),
              target: getTitle(over.id),
            })
          : undefined
      },
      onDragEnd: ({ active, over }) =>
        over
          ? t('sortableList.screenReaderAnnouncements.onDragEnd', {
              source: getTitle(active.id),
              target: getTitle(over.id),
            })
          : undefined,
      onDragCancel: ({ active }) =>
        t('sortableList.screenReaderAnnouncements.onDragCancel', { source: getTitle(active.id) }),
    },
    screenReaderInstructions: {
      draggable: t('sortableList.screenReaderInstructions'),
    },
  }
}

export function useSortableSensors() {
  return useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
    }),
    useSensor(PointerSensor, {
      // only start dragging after a certain distance, so button / checkbox clicks still work
      // (source: https://github.com/clauderic/dnd-kit/issues/591#issuecomment-1017050816)
      activationConstraint: { distance: 1 },
    }),
  )
}

export type DraggableRef = { isDragging: () => boolean }
export type DraggableRefProps = Readonly<{
  draggableRef: MutableRefObject<DraggableRef>
}>
export function ExposeIsDragging({ draggableRef }: DraggableRefProps) {
  useSortableDragStatus({ draggableRef })
  return null
}
function useSortableDragStatus({ draggableRef }: DraggableRefProps) {
  const isDragging = useRef(false)
  useImperativeHandle(draggableRef, () => ({ isDragging: () => isDragging.current }), [])

  useDndMonitor({
    onDragStart() {
      isDragging.current = true
    },
    onDragEnd: resetIsDragging,
    onDragCancel: resetIsDragging,
  })

  function resetIsDragging() {
    setTimeout(() => (isDragging.current = false), 0)
  }
}

export function ScrollIntoViewAfterDropped({ id }: { readonly id?: string }) {
  useDndMonitor({
    onDragEnd({ over }) {
      if (id && over?.id === id) {
        setTimeout(() => document.getElementById(id)?.scrollIntoView({ block: 'center' }), 0)
      }
    },
  })
  return null
}

// allow aborting keyboard controlled reordering via escape without closing the modal
export function handleEscapeKeyDown(onCancel: (...args: unknown[]) => void) {
  return function (e: KeyboardEvent) {
    if ((e.target as HTMLElement)?.hasAttribute?.('aria-pressed')) return
    onCancel(e)
  }
}
