import type { ReactElement } from 'react'
import { useRef, useState } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './DividerSettings'))

export default function DividerPlugin({
  data = Immutable.fromJS({ dividerType: 'solid' }),
  editorMode,
  editorView,
  onCancel,
  onDataChange,
  onSave,
}: Readonly<WorkspacePluginProps>): ReactElement | null {
  const [isSettingActive, setIsSettingActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const dividerType = data.get('dividerType')

  const pluginActiveClasses = cc([
    'dali-plugin-divider',
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  const divider = <hr style={{ borderStyle: dividerType }} />

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={(isSettingActive: boolean) => setIsSettingActive(isSettingActive)}
        onEscapeKeyDown={onCancel}
      >
        {({ renderLayout }) => <SettingsForm {...{ data, onDataChange, onSave, onCancel, renderLayout }} />}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {divider}
        {editorMode === 'edit' ? renderSettingsLayer() : null}
      </div>
    )
  }

  return !dividerType ? null : <div className={pluginActiveClasses}>{divider}</div>
}

DividerPlugin.actionBarButtons = { edit: true }
