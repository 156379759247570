import { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import Immutable from 'immutable'

import { DocumentMeta } from '../components/DocumentMeta'
import { PageOrCategoryContext } from './Category'
import { createMultiLanguageLinks } from './utils/createMultiLanguageLinks'
import { setPageAsync } from '../store/actions'
import Theme from '../components/Theme'

class Page extends Component {
  static propTypes = {
    viewError: map,
    page: map,
    shop: map,
    location: map,
  }

  static storeUpdate = (props, state) =>
    props.location.action === 'PUSH' || !state.getIn(['pages', props.params.pageSlug])
      ? [setPageAsync(props.params.pageSlug)]
      : []

  static contentCreationDisabled = () => false

  render() {
    const { viewError, page, shop, location } = this.props

    if (viewError || !page) {
      return (
        <>
          <DocumentMeta />
          <Theme withLayout error={viewError} currentView="page" />
        </>
      )
    }

    const isBeyondShop = Boolean(shop.get('beyond'))
    const isBeyondHomepage = isBeyondShop && page.get('slug') === 'home'

    // Use the merchant-provided title if set, otherwise use the page title.
    // Append the shop title.
    //
    // If it is a Beyond shop, the page could be the home page.
    // In this case, use the merchant-provided title if set, otherwise the shop
    // title with a fallback to the page title.
    const title = isBeyondHomepage
      ? page.get('titleTag') || shop.get('title') || page.get('title')
      : page.get('titleTag') || [page.get('title'), shop.get('title')].filter(Boolean).join(' - ')

    const meta = []
    if (page.get('metaDescription')) {
      meta.push({ name: 'description', content: page.get('metaDescription') })
    }
    // For category content
    if (location.getIn(['query', 'sort']) || location.getIn(['query', 'page'])) {
      meta.push({
        name: 'robots',
        content: 'noindex, follow',
      })
    }

    return (
      <PageOrCategoryContext.Provider value={this.props.page}>
        <DocumentMeta
          title={title}
          meta={meta}
          links={createMultiLanguageLinks(
            'blank',
            isBeyondHomepage ? '' : page.get('slugs', Immutable.List()).toJS(),
            shop,
          )}
        />
        <Theme withLayout currentView="page">
          {(renderView, props) =>
            renderView('Page', {
              ...props,
              page: page.toJS(),
            })
          }
        </Theme>
      </PageOrCategoryContext.Provider>
    )
  }
}

export default connect((state, props) => ({
  viewError: state.getIn(['view', 'error']),
  page: state.get('pages').get(props.params.pageSlug),
  shop: state.get('shop'),
  location: state.get('location'),
}))(Page)
