import type { LinkHTMLAttributes, MetaHTMLAttributes } from 'react'
import { useSelector } from 'react-redux'

import { useTranslate } from '../utils/translate'

export function DocumentMeta({
  title,
  meta,
  links,
}: Readonly<{
  title?: string
  meta?: MetaHTMLAttributes<HTMLMetaElement>[]
  links?: LinkHTMLAttributes<HTMLLinkElement>[]
}>) {
  const shop = useSelector<State, ImmutableMap>((state) => state.get('shop'))
  const viewError = useSelector<State, ImmutableMap | undefined>((state) => state.getIn(['view', 'error']))
  const t = useTranslate('shop')

  let documentTitle: string = title || shop.get('title')

  // If there is a view error, use a specific error document title.
  // Don’t use an error title for the special "shop closed" view error
  // (the error message is defined in error.ts in fusion-backend-ts).
  if (viewError && viewError.get('message') !== 'The requested shop is currently closed') {
    documentTitle = t('views.storefrontView.errorPageSection.errorcode', {
      code: viewError.get('statusCode'),
    })
  }

  return (
    <>
      <title>{documentTitle}</title>
      {meta?.map((meta) => <meta key={`${meta.name || meta.property}${meta.content}`} {...meta} />)}
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      {links?.map((link) => <link key={link.rel! + link.href + link.hrefLang} {...link} />)}
    </>
  )
}
