import type { LinkHTMLAttributes, PropsWithChildren, ReactElement } from 'react'
import type { RouterState } from 'react-router'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { DocumentMeta } from '../components/DocumentMeta'
import { getDefaultThemeSettings } from '../store/actions'
import { useTranslate } from '../utils/translate'
import CookieConsent from '../components/CookieConsent'
import ErrorPage from '../components/ErrorPage'
import NotificationOverlay from '../components/NotificationOverlay'
import PreviewBar from '../components/PreviewBar'
import XhrLoadingBar from '../components/XhrLoadingBar'

export default function Storefront({ children }: Readonly<PropsWithChildren>): ReactElement {
  const shopFaviconUrl = useSelector<State, string>((state) => state.getIn(['shop', 'faviconUrl']))
  const isShopClosed = useSelector<State, boolean>((state) => state.getIn(['shop', 'isClosed']))
  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))
  const isPreview = useSelector<State, boolean>((state) => Boolean(state.getIn(['location', 'query', 'preview'])))
  const isEditor = useSelector<State, boolean>((state) => state.getIn(['view', 'editorMode']))
  const isThemePreview = useSelector<State, boolean | undefined>((state) => state.getIn(['view', 'isThemePreview']))
  const viewError = useSelector<State, ImmutableMap | undefined>((state) => state.getIn(['view', 'error']))
  const viewErrorMessage = useSelector<State, string | undefined>((state) => state.getIn(['view', 'error', 'message']))

  // isIframe defaults to true in order to avoid a layout shift in the mobile preview.
  // This creates a layout shift in the test order preview instead, but there it's even helpful.
  const [isIframe, setIsIframe] = useState(true)
  const t = useTranslate('shop')
  useEffect(() => setIsIframe(window.parent !== window), [])

  if (
    // see error.ts in fusion-backend-ts
    viewErrorMessage === 'The requested shop is currently closed' ||
    (isBeyond && isShopClosed && !isPreview && !isThemePreview && !isEditor && !isIframe)
  ) {
    return (
      <div className="ep-shop-closed">
        <DocumentMeta />
        <h1 className="ep-shop-closed-headline">{t('views.storefrontClosedView.explanation')}</h1>
        <div className="ep-shop-closed-image" />
      </div>
    )
  }

  if (viewError?.get('statusCode') === 429) {
    return (
      <div data-testid="429-error-page">
        <DocumentMeta />
        <ErrorPage />
      </div>
    )
  }

  const shouldRenderPreviewBar = isBeyond && (isPreview || isThemePreview) && !isEditor && !isIframe

  return (
    <>
      {getFaviconLinks(shopFaviconUrl).map((link) => (
        <link key={link.href} {...link} />
      ))}
      <div className="ep-storefront-wrapper">
        <XhrLoadingBar />
        {shouldRenderPreviewBar && <PreviewBar />}
        {children}
        <CookieConsent isEditor={isEditor} />
      </div>
      {/* Make sure notification overlay is always on top of storefront */}
      <NotificationOverlay />
    </>
  )
}

Storefront.storeUpdate = (_props: unknown, state: State) => [
  !state.get('defaultThemeSettings') ? getDefaultThemeSettings(state.getIn(['shop', 'themeId'])) : null,
]

Storefront.loadableSsrChunks = (_props: RouterState, state: State) =>
  state.getIn(['shop', 'beyond']) ? ['account'] : []

function getFaviconLinks(shopFaviconUrl: string): LinkHTMLAttributes<HTMLLinkElement>[] {
  const isIcoFavicon = shopFaviconUrl?.endsWith('.ico')

  if (isIcoFavicon || !shopFaviconUrl) {
    return [
      {
        rel: 'shortcut icon',
        href: shopFaviconUrl || 'data:image/x-icon;,',
        type: 'image/x-icon',
      },
    ]
  }

  if (!isIcoFavicon && shopFaviconUrl) {
    return [
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: `${shopFaviconUrl}&width=16&height=16`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: `${shopFaviconUrl}&width=32&height=32`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: `${shopFaviconUrl}&width=96&height=96`,
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: `${shopFaviconUrl}&width=180&height=180`,
      },
    ]
  }

  return []
}
