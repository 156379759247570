import { omit } from 'lodash-es'
import { useRef } from 'react'
import cc from 'classcat'

import { PageOrCategoryContext } from '../../../../views/Category'
import { useTranslate } from '../../../../utils/translate'

type DropZoneProps = {
  orientation: 'horizontal' | 'vertical'

  // injected by DropTarget component
  isDragOver: boolean
  isDragActive: boolean
  isDropRedundant: boolean
  isOnEmptyPage: boolean
}

export default function DropZone(props: Readonly<DropZoneProps>) {
  const ref = useRef<HTMLDivElement>(null)

  const t = useTranslate('interface')

  return (
    <PageOrCategoryContext.Consumer>
      {(pageOrCategory) => {
        const pageType = pageOrCategory.get('type')

        const classNames = cc([
          'dali-grid-dropzone',
          `dali-grid-dropzone-${props.orientation}`,
          {
            'dali-grid-dropzone-empty': props.isOnEmptyPage,
            'dali-grid-dropzone-drop-redundant': props.isDropRedundant,
            'dali-grid-dropzone-dragactive': props.isDragActive,
            'dali-grid-dropzone-dragover': props.isDragOver,
          },
        ])
        const other = omit(props, [
          'isDragOver',
          'isDragActive',
          'isDropRedundant',
          'isOnEmptyPage',
          'tReady',
          'i18n',
          'i18nLoadedAt',
        ])
        return (
          <div ref={ref} {...other} className={classNames}>
            {props.isOnEmptyPage && (
              <section className={`dali-grid-dropzone-empty-container-${pageType}`}>
                <div className="dali-grid-dropzone-empty-image" />

                <div className="dali-grid-dropzone-empty-info">
                  <h1>{t('components.emptySpaceComponent.title')}</h1>
                  <p>{t('components.emptySpaceComponent.explanation')}</p>
                </div>
              </section>
            )}
          </div>
        )
      }}
    </PageOrCategoryContext.Consumer>
  )
}
